import React from "react";

// TODO: Expect changes to this shape
export const defaultStoreOwnerTreeViewContext = {
  screensLoading: false,
  storeOwners: [],
  screens: [],
  selectedStore: {},
  selectedScreen: {},
  onScreenChange: () => {},
  onStoreChange: () => {},
  onStoreOwnerChange: () => {}
};

const StoreOwnerTreeViewContext = React.createContext(
  defaultStoreOwnerTreeViewContext
);

export default StoreOwnerTreeViewContext;
