const defaultPalette = {
  id: "mosquitoNet",
  label: "Mosquito Net",
  backgroundColor: "white",
  header: "white",
  table: "rgba(255, 255, 255, 0.4)",
  tableHeader: "rgba(229, 229, 229)",
  grid: "white", // Table Grid Color
  tableHighlight: "rgba(255, 255, 255, 0.3)",
  textHeader: "black",
  textTable: "white",
  textTableHeader: "black",
};

export default defaultPalette;
