import React from "react";
import { Grid, FormControl, TextField, Typography } from "@material-ui/core";

const ColumnConfig = ({ selectedColumn, setSelectedColumnConfig }) => {
  const handleChange = (propName) => (event) => {
    const colCopy = { ...selectedColumn };
    colCopy[propName] = event.target.value;

    setSelectedColumnConfig((prev) => {
      const prevCopy = [...prev];
      const index = prevCopy.findIndex((col) => col.id === colCopy.id);

      prevCopy[index] = colCopy;

      return prevCopy;
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={2} style={{ display: "flex", alignItems: "flex-end" }}>
        <Typography variant="subtitle1">{selectedColumn.id}</Typography>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Label"
            value={selectedColumn.label}
            onChange={handleChange("label")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <FormControl fullWidth>
          <TextField
            label="BgColor"
            value={selectedColumn.backgroundColor}
            onChange={handleChange("backgroundColor")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <FormControl fullWidth>
          <TextField
            label="FontColor"
            value={selectedColumn.fontColor}
            onChange={handleChange("fontColor")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <FormControl fullWidth>
          <TextField
            label="BgColor"
            value={selectedColumn.dataBackgroundColor}
            onChange={handleChange("dataBackgroundColor")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <FormControl fullWidth>
          <TextField
            label="FontColor"
            value={selectedColumn.dataFontColor}
            onChange={handleChange("dataFontColor")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <FormControl fullWidth>
          <TextField
            label="ColWidth"
            value={selectedColumn.colWidth}
            onChange={handleChange("colWidth")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <FormControl fullWidth>
          <TextField
            label="Decimals"
            value={selectedColumn.decimals}
            onChange={handleChange("decimals")}
            type="number"
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ColumnConfig;
