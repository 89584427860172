import React, { useState } from "react";
import { areaCategories, dominances, pricePoints, sortOptions, thcDecimals } from "./service";
import PriceFilter from "../PriceFilter/PriceFilter";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { Typography } from "@mui/material";

import ReorderColumns from "../UI/ReorderColumns";
import CustomizedSelect from "../Select/Select";
import ColumnConfig from "../UI/ColumnConfig";

import columns from "../../tmpl/menus/columns";
import { menuCategories } from "./service";
import iconSets from "../../icons/icons";

const EditModal = ({
    open,
    onClose,
    selectedCategory,
    setSelectedCategory,
    area,
    availableType,
    taxData,
    onAreaChange,
    setSelectedColumns,
    retrieveType,
    selectedColumns,
    fetchInventory,
    selectedColumnConfig,
    setSelectedColumnConfig,
}) => {

    const [dominanceIcon, setDominanceIcon] = useState(
        area.settings
            ? area.settings.dominanceIcon === true
                ? "Circle Gradients"
                : area.settings.dominanceIcon === false
                ? "none"
                : area.settings.dominanceIcon
            : "none"
    );

    const isThcDecimalInArea = ![null, undefined].includes(area.thcDecimal);

    const [title, setTitle] = useState(area.title ? area.title : "");
    const [highlight, setHighlight] = useState(area.settings ? area.settings.highlight : false);
    const [autoScroll, setAutoScroll] = useState(area.settings ? area.settings.autoScroll : false);
    const [multiplyScroll, setMultiplyScroll] = useState(
      area.settings ? area.settings.multiplyScroll : false
    );
    const [domFilter, setDomFilter] = useState(area.domFilter ? area.domFilter : []);
    const [typeFilter, setTypeFilter] = useState(area.typeFilter ? area.typeFilter : []);
    const [sorting, setSorting] = useState(area.sort ? area.sort : []);
    const [overflow, setOverflow] = useState(area.overflow ? area.overflow : 0);
    const [overflowRows, setOverflowRows] = useState(area.overflowRows ? area.overflowRows : 0);
    const [priceFilter, setPriceFilter] = useState(area.priceFilter ? area.priceFilter : []);
    const [thcDecimal, setThcDecimal] = useState(isThcDecimalInArea ? area.thcDecimal : 'Default');
    const [priceFilterRestriction, setPriceFilterRestriction] = useState(
        area.priceFilterRestriction ? area.priceFilterRestriction : []
    );

    const [filterBy, setFilterBy] = useState(area.settings ? area.settings.filterBy : "");
    const [filter, setFilter] = useState(area.settings ? area.settings.filter : "");
    const [excludeBy, setExcludeBy] = useState(area.settings ? area.settings.excludeBy : "");
    const [exclude, setExclude] = useState(area.settings ? area.settings.exclude : "");

    const [headerTextTransform, setHeaderTextTransform] = useState(area.settings?.headerTransform || "n/a");
    const [dataTextTransform, setDataTextTransform] = useState(area.settings?.dataTransform || "n/a");
    const [hideNoCents, setHideNoCents] = useState(area.settings?.hideNoCents || "n/a");

    const [quantityOperator, setQuantityOperator] = useState(area.settings.quantityOperator ?? "eq");
    const [quantity, setQuantity] = useState(area.settings.quantity ?? null); 

    let availableColumns = selectedCategory
        ? columns.filter((column) => !column.restrict.includes(selectedCategory))
        : null;

    const columnToIndex = (columns) => {
        return columns.map((column) =>
            availableColumns.map((column) => column.field).indexOf(column)
        );
    };
    
    // Takes list of indices and converts to column names
    const indexToColumn = (indices) => {
        return indices.map((colIndex) => availableColumns[colIndex].field);
    };

    // Sets new selected Area, sets the available columns for selection, and sets the default columns
    const handleAreaSelect = (event) => {
        setSelectedCategory(event.target.value);
        availableColumns = columns.filter(
            (column) => !column.restrict.includes(event.target.value)
        );
        //! Fix default with multi select
        setSelectedColumns(
            availableColumns
                .filter((column) => column.default.includes(event.target.value[0]))
                .map((column) => column.field)
        );
        //! Fix default title with multi select
        setTitle(event.target.value);
        // retrieveDom(event.target.value);
        retrieveType(event.target.value);
    };

    const handleTitle = (event) => {
        setTitle(event.target.value);
    };

    const handleColumnSelect = (event) => {
        setSelectedColumns(indexToColumn(event.target.value));
    };

    const handleDomFilterSelect = (event) => {
        setDomFilter(event.target.value);
    };

    const handleTypeFilterSelect = (event) => {
        setTypeFilter(event.target.value);
    };

    const handleIconSelect = (event) => {
        setDominanceIcon(iconSets[event.target.value].name);
    };

    const handleHighlight = () => {
        setHighlight(!highlight);
    };

    const handleAutoScroll = () => {
        setAutoScroll(!autoScroll);
    };

    const handleMultiplyScroll = () => {
      setMultiplyScroll(!multiplyScroll);
    };

    const handleAddSort = () => {
        setSorting([...sorting, ""]);
    };

    const handleRemoveSort = () => {
        setSorting(sorting.slice(0, -1));
    };

    const handleAddPriceFilter = () => {
        setPriceFilter([...priceFilter, {}]);
    };

    const handleRemovePriceFilter = () => {
        setPriceFilter(priceFilter.slice(0, -1));
    };

    const handleFilterUpdate = (updatedFilter, index) => {
        const newPriceFilter = [...priceFilter];
        newPriceFilter[index] = updatedFilter;
        setPriceFilter(newPriceFilter);
    };

    const handlePriceFilterRestrictionSelect = (event) => {
        setPriceFilterRestriction(event.target.value);
    };

    const handleThcDecimalsSelect = (event) => {
        const { value } = event.target; 
        if(value.toLowerCase() === 'default'){
           setThcDecimal("Default"); 
        } else {
           setThcDecimal(Number.parseInt(value, 10)); 
        }
    };

    const handleSortingSet = (event, index) => {
        setSorting(sorting.map((element, ind) => (ind === index ? event.target.value : element)));
    };

    const handleOverflowSelect = (event) => {
        setOverflow(event.target.value);
    };

    const handleOverflowRowsSelect = (event) => {
        setOverflowRows(event.target.value);
    };

    // Submits all data changes
    const handleSetArea = () => {
        const newArea = {
            ...area,
            title: title,
            category: selectedCategory,
            columns: selectedColumns,
            settings: {
                highlight,
                dominanceIcon,
                autoScroll,
                multiplyScroll,
                headerTransform: headerTextTransform,
                dataTransform: dataTextTransform,
                columnConfig: selectedColumnConfig || [],
                quantityOperator,
                quantity,
                filterBy: filterBy || null,
                filter: filter || null,
                excludeBy: excludeBy || null,
                exclude: exclude || null,
                hideNoCents: hideNoCents || null,
            },
            domFilter,
            typeFilter,
            sort: sorting.filter((element) => element !== ""),
            overflow,
            overflowRows,
            priceFilter,
            priceFilterRestriction,
            thcDecimal,
        };
        if (
            menuCategories.includes(selectedCategory) ||
            menuCategories.some((cat) => selectedCategory.includes(cat))
        ) {
            newArea.type = "menu";
        } else if (selectedCategory === "Ad" || selectedCategory.includes("Ad")) {
            newArea.type = "ad";
        } else if (selectedCategory === "Content" || selectedCategory.includes("Content")) {
            newArea.type = "content";
        } else if (selectedCategory === "Placeholder" || selectedCategory.length === 0) {
            newArea.type = "placeholder";
        }

        console.log(newArea);

        onAreaChange(newArea);
        fetchInventory();
        onClose();
    };

    return (
      <>
        {open && (
          <Dialog open onClose={onClose} fullWidth={true} maxWidth="lg">
            <DialogTitle id="area-dialog-title">Area Settings</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <FormControl>
                    <InputLabel>Choose Area</InputLabel>
                    <Select
                      style={{ width: "100px" }}
                      multiple
                      value={selectedCategory}
                      onChange={handleAreaSelect}
                    >
                      {areaCategories.map((category) => (
                        <MenuItem value={category} key={`area-${category}`}>
                          {category}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl>
                    <TextField
                      id="area-title"
                      label="Area Title"
                      value={title}
                      onChange={handleTitle}
                      focused={true} //Required otherwise when selecting area text and title overlap
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    <InputLabel>Choose Columns</InputLabel>
                    <Select
                      style={{ width: "300px" }}
                      multiple
                      value={columnToIndex(selectedColumns)}
                      onChange={handleColumnSelect}
                    >
                      {availableColumns ? (
                        availableColumns.map((column, index) => (
                          <MenuItem
                            value={index}
                            key={`column-${column.field}`}
                          >
                            {column.field}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>Select Area first</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl>
                    <InputLabel>Dominance Restriction</InputLabel>
                    <Select
                      style={{ width: "300px" }}
                      multiple
                      value={domFilter}
                      onChange={handleDomFilterSelect}
                    >
                      {dominances.map((dom) => (
                        <MenuItem value={dom} key={`dominance-${dom}`}>
                          {dom}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    <InputLabel>Type Restriction</InputLabel>
                    <Select
                      style={{ width: "300px" }}
                      multiple
                      value={typeFilter}
                      onChange={handleTypeFilterSelect}
                    >
                      {availableType ? (
                        availableType.map((type) => (
                          <MenuItem
                            value={type.Type}
                          >{`${type.Type}`}</MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>Select Area first</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={highlight}
                        onChange={handleHighlight}
                        name="highlight"
                      />
                    }
                    label="Alternating Highlight"
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={3}>
                  <FormControl>
                    <InputLabel>Dominance Icon</InputLabel>
                    <Select
                      style={{ width: "200px" }}
                      value={iconSets
                        .map((set) => set.name)
                        .indexOf(dominanceIcon)}
                      onChange={handleIconSelect}
                    >
                      {iconSets.map((iconSet, index) => (
                        <MenuItem value={index} key={`icon-${iconSet.name}`}>
                          {iconSet.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {sorting.length < 3 ? (
                    <Button onClick={handleAddSort}>Add Sort</Button>
                  ) : (
                    <div />
                  )}
                  {sorting.length > 0 ? (
                    sorting.map((sortValue, index) => (
                      <FormControl disabled={sorting[index - 1] === ""}>
                        <InputLabel>Sorting {index}</InputLabel>
                        <Select
                          style={{ width: "200px" }}
                          value={sortValue}
                          onChange={(event) => {
                            handleSortingSet(event, index);
                          }}
                        >
                          {sortOptions.map((sortOption) => (
                            <MenuItem value={sortOption}>{sortOption}</MenuItem>
                          ))}
                        </Select>
                        {index === sorting.length - 1 ? (
                          <Button onClick={handleRemoveSort}>
                            Remove Sort
                          </Button>
                        ) : (
                          <div />
                        )}
                      </FormControl>
                    ))
                  ) : (
                    <div />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel>Overflow Areas</InputLabel>
                    <Select
                      style={{ width: "300px" }}
                      value={overflow}
                      onChange={handleOverflowSelect}
                    >
                      <MenuItem value={0}>None</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Overflow Rows</InputLabel>
                    <Select
                      style={{ width: "300px" }}
                      value={overflowRows}
                      onChange={handleOverflowRowsSelect}
                    >
                      {new Array(40)
                        .fill(0)
                        .map((_, i) => i + 1)
                        .map((rows) => {
                          return (
                            <MenuItem value={rows} key={`overflow-${rows}`}>
                              {rows}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  {priceFilter.length < 1 ? (
                    <Button onClick={handleAddPriceFilter}>
                      Add Price Filter
                    </Button>
                  ) : (
                    <div />
                  )}
                  {priceFilter.length > 0 ? (
                    priceFilter.map((filterSettings, index) => (
                      <PriceFilter
                        handleFilterUpdate={handleFilterUpdate}
                        index={index}
                        filterOption={filterSettings.filterOption}
                        filterNumbers={filterSettings.filterNumbers}
                        pricePoint={filterSettings.pricePoint}
                        key={`pricefilter-${filterSettings.filterOption}`}
                      />
                    ))
                  ) : (
                    <div />
                  )}
                  {priceFilter.length > 0 ? (
                    <Button onClick={handleRemovePriceFilter}>
                      Remove Price Filter
                    </Button>
                  ) : (
                    <div />
                  )}
                </Grid>
                <Grid item xs={6}>
                  {priceFilter.length > 0 ? (
                    <FormControl>
                      <InputLabel>Increment Restriction</InputLabel>
                      <Select
                        style={{ width: "200px" }}
                        multiple
                        value={priceFilterRestriction}
                        onChange={handlePriceFilterRestrictionSelect}
                      >
                        {pricePoints.map((pricePoint) => (
                          <MenuItem
                            value={pricePoint}
                            key={`increment-${pricePoint}`}
                          >
                            {pricePoint}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <div />
                  )}
                </Grid>
                <Grid item xs={12} style={{ display: "flex" }}>
                  <p style={{ marginRight: 200 }}>Number of THC decimals:</p>
                  <FormControl>
                    <InputLabel>Select THC decimals</InputLabel>
                    <Select
                      style={{ width: "200px" }}
                      value={thcDecimal}
                      onChange={handleThcDecimalsSelect}
                    >
                      {thcDecimals.map((decimal) => (
                        <MenuItem value={decimal} key={`decimals-${decimal}`}>
                          {decimal}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <p>{`Tax Rate: ${taxData.taxRate}`}</p>
                  <p>{`Tax applied to all screens: ${taxData.applyTaxToScreens}`}</p>
                  <p>{`Rounding Value: ${taxData.roundingValue}`}</p>
                  <p>{`Rounding applied to all screens: ${taxData.applyRoundingToScreens}`}</p>
                </Grid>
                <Grid item xs={6}>
                  <CustomizedSelect
                    id="orientation-selector"
                    label="Header Text Transform"
                    value={headerTextTransform}
                    items={[
                      { id: "n/a", label: "N/A" },
                      { id: "none", label: "None (as-is)" },
                      { id: "lowercase", label: "Lowercase" },
                      { id: "uppercase", label: "Uppercase" },
                      { id: "capitalize", label: "Capitalize" },
                    ]}
                    onChange={(e) => setHeaderTextTransform(e)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomizedSelect
                    id="orientation-selector"
                    label="Data Text Transform"
                    value={dataTextTransform}
                    items={[
                      { id: "n/a", label: "N/A" },
                      { id: "none", label: "None (as-is)" },
                      { id: "lowercase", label: "Lowercase" },
                      { id: "uppercase", label: "Uppercase" },
                      { id: "capitalize", label: "Capitalize" },
                    ]}
                    onChange={(e) => setDataTextTransform(e)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomizedSelect
                    id="orientation-selector"
                    label="Price Decimals"
                    value={hideNoCents}
                    items={[
                      { id: "n/a", label: "N/A" },
                      { id: "hideNoCents", label: "Hide No Cents" },
                    ]}
                    onChange={(e) => setHideNoCents(e)}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <ReorderColumns
                    selectedColumns={selectedColumns}
                    setSelectedColumns={setSelectedColumns}
                  />
                </Grid>
                <Grid item xs={6}></Grid>

                <Grid item xs={12}>
                  <h3>Column Config</h3>
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item xs={5}></Grid>
                  <Grid
                    item
                    xs={2}
                    container
                    justifyContent="center"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    <Typography variant="subtitle1">Headers</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    container
                    justifyContent="center"
                    style={{ backgroundColor: "#efefef80" }}
                  >
                    <Typography variant="subtitle1">Data</Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
                <Grid item xs={12}>
                  {selectedColumnConfig.map((selectedColumn, index) => (
                    <ColumnConfig
                      selectedColumn={selectedColumn}
                      selectedColumnConfig={selectedColumnConfig}
                      setSelectedColumnConfig={setSelectedColumnConfig}
                      index={index}
                    />
                  ))}
                </Grid>

                <Grid item xs={12}>
                  <h3>Scrolling</h3>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={autoScroll}
                            onChange={handleAutoScroll}
                            name="autoScroll"
                          />
                        }
                        label="Auto Scroll"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={multiplyScroll}
                            onChange={handleMultiplyScroll}
                            name="multiplyScroll"
                          />
                        }
                        label="Multiply Scroll (10x)"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <h3>Menu Filters</h3>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <CustomizedSelect
                        label="Filter By (column)"
                        value={filterBy}
                        items={[
                          { id: "", label: "None" },
                          ...columns.map((column) => ({
                            id: column.field,
                            label: column.field,
                          })),
                        ]}
                        onChange={(e) => setFilterBy(e)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="text"
                        label="Filter Terms (comma-separated)"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomizedSelect
                        label="Exclude By (column)"
                        value={excludeBy}
                        items={[
                          { id: "", label: "None" },
                          ...columns.map((column) => ({
                            id: column.field,
                            label: column.field,
                          })),
                        ]}
                        onChange={(e) => setExcludeBy(e)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="text"
                        label="Exclude Terms (comma-separated)"
                        value={exclude}
                        onChange={(e) => setExclude(e.target.value)}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomizedSelect
                        label="Quantity Operator"
                        value={quantityOperator}
                        items={[
                          { id: "eq", label: "Equals" },
                          { id: "lt", label: "Less Than" },
                          { id: "lte", label: "Less Than / Equal To" },
                          { id: "gt", label: "Greater Than" },
                          { id: "gte", label: "Greater Than / Equal To" },
                        ]}
                        onChange={(e) => setQuantityOperator(e)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <TextField
                        type="number"
                        label="Quantity"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: "0", // Minimum value
                          max: "100", // Maximum value
                          step: "1", // Step size
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSetArea}>Set Area</Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
};
export default EditModal;
