var ActionType = {
  //TreeView Actions
  SET_SELECTED_STORE: "SET_SELECTED_STORE",
  SET_SELECTED_SCREEN: "SET_SELECTED_SCREEN",
  STORE_OWNERS_LOADING: "STORE_OWNERS_LOADING",
  STORE_OWNERS_FETCHED: "STORE_OWNERS_FETCHED",
  //ScreenEditor Actions
  LAYOUT_CHANGED: "LAYOUT_CHANGED",
  PALETTE_CHANGED: "PALETTE_CHANGED",
  THEME_CHANGED: "THEME_CHANGED",
  SAVE_BUTTON_CLICKED: "SAVE_BUTTON_CLICKED",
  DRAGGABLE_BUTTON_CLICKED: "DRAGGABLE_BUTTON_CLICKED",
  SCREEN_SAVED: "SCREEN_SAVED",

  //AreaEditor Actions
  AREA_TYPE_CHANGED: "AREA_TYPE_CHANGED",
  //MenuEditor Actions

  //Menu component Actions
  MENU_DATA_LOADING: "MENU_DATA_LOADING",
  MENU_DATA_FETCHED: "MENU_DATA_FETCHED"

  //PricingMatrix Actions

  //ContentEditor Actions

  //AdEditor Actions

  //BrandingArea Actions
};
export default ActionType;
