import thunk from 'redux-thunk';
import { createStore, compose, applyMiddleware } from 'redux';
import reducers from "./App.Root.Reducer"

const appStore = createStore(
    reducers,
    compose(
        applyMiddleware(thunk),
        window.devToolsExtension ? window.devToolsExtension() : f => f
    )
);

export default appStore;