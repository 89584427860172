import React from "react";
import defaultPalette from "../../palettes/defaultPalettes/defaultPalette";
import defaultThemeFactory from "../../themes/defaultTheme";
import { map as layoutMap } from "../../layouts/layoutFactory";

// SCREEN STRUCTURE
/*
{
  editMode: (bool),
  id: (int),
  storeId: (int),
  name: (string),
  config: {
    title: (string),
    socialMediaHandle: (string),
    socialMediaColor: (string),
    background: (string) / {folder: (string), fileName: (string)},
    brandingSetting: (bool)
    logo: (string) / {folder: (string), fileName: (string)},
    paletteId: (string),
    themeId: (string),
    layoutId: (string),
    areas: [
      {
        id: (string),
        type: (string),
        category: (string),
        columns: (obj),
        settings: (obj),
        typeFilter: (array),
        domFilter: (array),
        sort: (array),
      },
    ],
    fontOverrides: {
      titleFont: (string),
      titleFontSize: (int),
      headerFont: (string),
      headerFontSize: (int),
      dataFont: (string),
      dataFontSize: (int),
    },
  },
****NOT SAVED TO DB****
  model: {
    palette: (paletteObj),
    theme: (themeObj),
    layout: {},
    areas: [
      {
        id: (string),
        type: (string),
        category: (string),
        columns: (obj),
        data: (obj),
        title: (string),
        settings: {
          highlight: (bool),
        },
        sort: (array),
      }
    ],
    socialMediaHandle: (string),
    socialMediaColor: (string),
    logo: (string),
    background: (string),
    fontOverrides: {
      titleFont: (string),
      titleFontSize: (int),
      headerFont: (string),
      headerFontSize: (int),
      dataFont: (string),
      dataFontSize: (int),
    },
    ... Maybe others
  }
}
*/

export const defaultScreenContext = {
  editMode: false,
  id: 0,
  storeId: 0,
  name: "Untitled",
  config: {
    title: "Untitled",
    socialMediaHandle: "@greenscreenstv",
    socialMediaColor: "black",
    brandingSetting: true,
    socialSetting: true,
    background: {
      fileName: "defaultBg.jpg",
      folder: "backgrounds",
    },
    logo: {
      fileName: "defaultLogo.png",
      folder: "logos",
    },
    paletteId: defaultPalette.id,
    themeId: defaultThemeFactory().id,
    layoutId: layoutMap[0].id,
    areas: layoutMap[0].value.map((v) => {
      return {
        id: v.i,
        type: "placeholder",
        // category: "placeholder", //Added for temporary data fetch fix
      };
    }),
    areas: [
      {
        id: "a",
        type: "menu",
        title: "Flower",
        category: ["Flower"],
        columns: ["ProductName", "Dominance", "PriceGram"],
        domFilter: [],
        typeFilter: [],
        settings: {
          autoScroll: false,
          dominanceIcon: "Circle Gradients",
          highlight: false,
        },
        sort: [],
      },
      {
        id: "b",
        type: "menu",
        title: "Edible",
        category: ["Edible"],
        columns: ["ProductName", "Price", "Brand"],
        domFilter: [],
        typeFilter: [],
        settings: {
          autoScroll: false,
          dominanceIcon: "none",
          highlight: false,
        },
        sort: [],
      },
      {
        id: "c",
        type: "menu",
        title: "Joint",
        category: ["Joint"],
        columns: ["ProductName", "Thc", "Price"],
        domFilter: [],
        typeFilter: [],
        settings: {
          autoScroll: false,
          dominanceIcon: "none",
          highlight: false,
        },
        sort: [],
      },
    ],
  },
};

const ScreenContext = React.createContext(defaultScreenContext);

export default ScreenContext;
