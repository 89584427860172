export default [
  "Jazz Ball Bold",
  "Jazz Ball Regular",
  "Sketch Block",
  "Zion",
  "Titilium Web",
  "Spectral",
  "Roboto",
  "Roboto Mono",
  "Raleway",
  "Poppins",
  "Lora",
  "Facile Sans",
  "FF Mark Pro Book",
  "FF Mark Pro Bold",
  "Montserrat Light",
  "Montserrat Bold",
  "Bevan",
  "Open Sans",
  "Averia Serif Libre",
  "Industry Bold",
  "Industry Demi",
  "Aktiv Grotesk Corp Regular",
  "Aktiv Grotesk Corp Bold",
  "Exo Bold",
  "Exo SemiBold",
  "Gotham Black",
  "Mission Gotham Black",
  "Mission Gotham Bold",
  "Mission Gotham Regular",
  "Helvetica Bold",
  "Knockout Heavy",
  "Knockout Junior",
  "Prompt Extra Light",
  "Julius Sans One Regular",
  "Worker Black",
  "Bicyclette Black",
  "Bicyclette Bold",
];
