import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import Screen from "../../components/Screen";
import ActionType from "../../App.ActionType";
import Select from "../../components/Select";
import StoreOwnerTreeView from "../../components/StoreOwnerTreeView";

import {
  createFetchingTreeViewDataAction,
  createFetchingAllTreeViewDataAction,
  createSaveCurrentContentsDataAction,
  createGragContentsDataAction
} from "./Admin.Action";
import { useToasts } from "react-toast-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";

import { themeOptions } from "../../themes/themeFactory";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  circularProgress: {
    width: "100%",
    display: "float-row",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  }
}));

const Admin = props => {
  console.log(props);
  const state = useSelector(state => state.admin.state);
  console.log(state);
  const screenModel = useSelector(
    state => state.admin.state.currentScreenModel
  );
  console.log(screenModel);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { addToast } = useToasts();

  const layouts = [
    { id: "1", label: "3 columns" },
    { id: "2", label: "3 columns, mid split" },
    { id: "3", label: "3 columns, left split" },
    { id: "4", label: "3 columns, right split" }
  ];

  const themes = themeOptions();

  useEffect(() => {
    dispatch(createFetchingAllTreeViewDataAction());
    dispatch(createFetchingTreeViewDataAction());
  }, [dispatch]);

  useEffect(() => {
    addToast(JSON.stringify(state.notifications, null, " "), {
      appearance: "success",
      autoDismiss: true,
      placement: "top-center"
    });
  }, [state.notifications, addToast]);

  const onLayoutChange = option => {
    dispatch({
      type: ActionType.LAYOUT_CHANGED,
      payload: {
        id: option
      }
    });
  };

  const onThemeChange = option => {
    dispatch({
      type: ActionType.THEME_CHANGED,
      payload: option
    });
  };

  const onSaveClick = () => {
    addToast("SAVE_BUTTON_CLICKED", {
      appearance: "success",
      autoDismiss: true,
      placement: "top-center"
    });

    dispatch(
      createSaveCurrentContentsDataAction(screenModel.contents, state.layout)
    );
  };

  const onDragButtonClicked = () => {
    addToast("DRAGGABLE_BUTTON_CLICKED", {
      appearance: "success",
      autoDismiss: true,
      placement: "top-center"
    });

    dispatch(createGragContentsDataAction());
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={2} className={classes.circularProgress}>
          {state.loadingTreeView && <CircularProgress />}
          {!state.loadingTreeView && <StoreOwnerTreeView />}
        </Grid>
        <Grid item xs={12} sm={10}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={1} />
            <Grid item xs={12} sm={3}>
              <Select
                id="theme-selector"
                key="theme-selector"
                value={screenModel.selectedTheme.id}
                label="Select Theme"
                items={themes}
                onChange={onThemeChange}
              />
            </Grid>
            <Grid item xs={12} sm={1} />
            <Grid item xs={12} sm={3}>
              <Select
                id="layout-selector"
                key="layout-selector"
                value={screenModel.selectedLayout.id}
                label="Select Layout"
                items={layouts}
                onChange={onLayoutChange}
              />
            </Grid>
            <Grid item xs={12} sm={1} />
            <Grid
              item
              xs={12}
              sm={3}
              container
              spacing={10}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSaveClick}
                >
                  Save
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={onDragButtonClicked}
                >
                  Drag
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <Screen />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Admin;
