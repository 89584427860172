import React, { useState } from "react";
import axios from "axios";
import * as appFunc from "../../App.Func";
import { makeStyles } from '@material-ui/core/styles';
import styles from "./imageScreenStyle";
import "./imageScreen.css";

import Carousel from "react-multi-carousel";

import GRIcon from "../../assets/img/icons/GRIcon.png";
import ChocolatesIcon from "../../assets/img/icons/ChocolatesIcon.png";
import ExtractIcon from "../../assets/img/icons/ExtractIcon.png";
import FruitChewsIcon from "../../assets/img/icons/FruitChewsIcon.png";
import VapeIcon from "../../assets/img/icons/VapeIcon.png";

const useStyles = makeStyles(styles);

const ImageScreen = (props) => {
  const { storeId, category } = props;
  const [inventoryData, setInventoryData] = useState();

  const orientation = window.innerHeight > window.innerWidth ? "Vertical" : "Horizontal";

  const loadInventory = () => {
    let cat;
    if(category === "Fruit Chews" || category === "Chocolates") {
      cat = "Edible";
    } else {
      cat = category;
    }
    axios
        .get(`${appFunc.getApiUrl()}/Inventory/${storeId}/${cat}`)
        .then((result) => {
          if(category === "Fruit Chews" || category === "Chocolates") {
            setInventoryData(result.data.filter(product => {
              return product.Type === category;
            }));
          } else {
            setInventoryData(result.data);
          }
        });
  }

  if (!inventoryData) {
    loadInventory();
    setInterval(loadInventory, 600000);
  }

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const categoryIcons = {
    "Extract": ExtractIcon,
    "Vape": VapeIcon,
    "Fruit Chews": FruitChewsIcon,
    "Chocolates": ChocolatesIcon,
  }

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.topBar}>
        <img className={classes.icon} alt="category_icon" src={categoryIcons[category]}/>
        <div className={classes.category}>{category.toUpperCase()}</div>
      </div>
        {inventoryData && <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={inventoryData.length > 1}
          autoPlaySpeed={15000}
          arrows={false}
          itemClass='carousel-item-padding'
          sliderClass="carousel-height"
        >
          {inventoryData.map(product => {
            return (
              <div className={classes.product + " " + classes[`product${orientation}`]}>
                <div className={classes.imageContainer + " " + classes[`separation${orientation}`]}>
                  <img className={classes.image + " " + classes[`image${orientation}`]} alt="product" src={product.PictureUrl}/>
                </div>
                <div className={classes.productInfo + " " + classes[`separation${orientation}`]}>
                  <div className={classes.productTitle + " " + classes[`productMargin${orientation}`]}>
                    <div className={classes.productName}>{product.ProductName}</div>
                    <div className={classes.productBrand}>
                      {product.Brand === "Garden Remedies" && <img className={classes.brandIcon} alt="brand_icon" src={GRIcon} />}
                      {product.Brand}
                    </div>
                    <div className={classes.productDominance}>{product.Dominance}</div>
                  </div>
                  <div className={classes.productDetails}>
                    <div className={classes.highlightContainer}>
                      <div className={classes.productPriceContainer}>
                        <div className={classes.pricePrefix}>Price:</div>
                        <div className={classes.productPrice}>${product.Price}</div>
                      </div>
                    </div>
                    {(category !== "Extract" && category !== "Vape") ? (
                      <div className={classes.productExtraInfo1}>
                        <div className={classes.extraTitle}>THC / piece</div>
                        <div className={classes.extra}>{product.Thc}{product.CannaUnit}</div>
                      </div>
                    ) : (
                      <div className={classes.productExtraInfo2}>
                        <div>
                          <div className={classes.extraTitle}>THC</div>
                          <div className={classes.extra}>{product.Thc}{product.CannaUnit}</div>
                        </div>
                        <div className={classes.bar}></div>
                        <div>
                          <div className={classes.extraTitle}>Weight</div>
                          <div className={classes.extra}>{product.Weight}{product.Unit}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </Carousel>}
      </div>
  )
};

export default ImageScreen;