const categoryStyle = {
  category: {
    height: "100%",
    overflow: "hidden",
    color: "white",
  },
  title: {
    fontFamily: "Roboto Black",
    letterSpacing: "15px",
    textAlign: "left",
    height: "75px",
    display: "flex",
    alignItems: "center",
  },
  titleSizeVert: {
    fontSize: "75px",
  },
  titleSizeHor: {
    fontSize: "50px",
  },
  carousel: {
    height: "100%",
  },
};

export default categoryStyle;