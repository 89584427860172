import { base, darkTheme, initialTheme, colorOptions } from "./App.Theme";
import ActionType from "./App.ActionType";

const initialState = {
  ...base,
  ...initialTheme,
  ...colorOptions.blue
};

const themeReducer = function(state = initialState, action) {
  switch (action.type) {
    case ActionType.SET_DARK_THEME:
      return {
        theme: darkTheme
      };
    default: {
      return state;
    }
  }
};

export default themeReducer;
