const tanPlan = {
  id: "tanPlan",
  label: "Tan Plan",
  backgroundColor: "#F9F6E7",
  header: "white", //#323232
  table: "rgba(255, 255, 255, 0.6)", //#F9F6E7
  tableHeader: "black", //#F9F6E7
  grid: "#A0948D", // Table Grid Color
  tableHighlight: "light grey",
  textHeader: "black",
  textTable: "black",
  textTableHeader: "white",
};

export default tanPlan;
