import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import styles from './productStyle.js';
import GRIcon from "./../../assets/img/icons/GRIcon.png";

const useStyles = makeStyles(styles);

const Product = (props) => {
  const { product, last, division } = props;

  const orientation = window.innerHeight > window.innerWidth;

  const classes = useStyles();
  return (
    <div className={`${classes.container} 
    ${orientation ? classes.containerHeightVert : division === "third" ? classes.containerHeightHorThird : classes.containerHeightHorHalf} 
    ${!last && orientation ? classes.containerMargin : classes.containerNoMargin}`}>
      <div className={division === "third" ? classes.nameThird : classes.name}>{product.ProductName}</div>
      <div className={classes.price}>${product.Price}</div>
      <div className={`${classes.info} ${orientation ? classes.infoSizeVert : division === "third" ? classes.infoSizeHorThird : classes.infoSizeHorHalf}`}>
        <span className={classes.bold}>THC: </span>
        <span>{product.Thc}{product.CannaUnit}</span>
        {(product.Type === "Fruit Chews" || product.Type === "Chocolates") && product.Cbd !== 0 && product.Cbd !== null  &&
          <span className={`${classes.bold} ${classes.line}`}>|</span>
        }
        {(product.Type === "Fruit Chews" || product.Type === "Chocolates") && product.Cbd !== 0 && product.Cbd !== null  &&
          <span className={classes.bold}>CBD: </span>
        }
        {(product.Type === "Fruit Chews" || product.Type === "Chocolates") && product.Cbd !== 0 && product.Cbd !== null  &&
          <span>{product.Cbd}{product.CannaUnit}</span>
        }
        {product.Category !== "Edible" && product.Category !== "Tincture" && product.Category !== "Topical" && 
          <span className={`${classes.bold} ${classes.line}`}>|</span>
        }
        {product.Category !== "Edible" && product.Category !== "Tincture" && product.Category !== "Topical" && 
          <span className={classes.bold}>Weight: </span>
        }
        {product.Category !== "Edible" && product.Category !== "Tincture" && product.Category !== "Topical" && 
          <span>{product.Weight}G</span>
        }
        {!!product.Brand && 
          <span className={`${classes.bold} ${classes.line}`}>|</span>
        }
        <span>{product.Brand}</span>
      </div>
      {/* <img src={GRIcon} style={{height: "20px", width: "20px", position: "absolute"}}></img> */}
    </div>
  )
};

export default Product;