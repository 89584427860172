const tabletPalette = {
  id: "default",
  label: "Default",
  backgroundColor: "white",
  header: "#6AA033",
  primary: "#6AA033",
  table: "white",
  tableHeader: "white",
  secondary: "white",
  grid: "black", // Table Grid Color
  tableHighlight: "gray",
  textHeader: "white",
  textTable: "black",
  textTableHeader: "black",
};

export default tabletPalette;
