const organicNoon = {
  id: "organicNoon",
  label: "Organic Noon",
  backgroundColor: "#F6F2F2",
  header: "#908156", //#A76A30
  table: "#F6F2F2",
  tableHeader: "#F6F2F2",
  grid: "#BA834D", // Table Grid Color
  tableHighlight: "#ECE3E3",
  textHeader: "white",
  textTable: "black",
  textTableHeader: "black",
};

export default organicNoon;
