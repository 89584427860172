import { combineReducers } from "redux";
import ActionType from "../../App.ActionType";
import { map as themeMap } from "../../themes/themeFactory";
import { map as layoutMap } from "../../layouts/layoutFactory";
import { CompomentType, DataType } from "../../App.ComponentType";

let defaultScreenModel = {
  selectedTheme: themeMap.defaultTheme,
  originalTheme: themeMap.defaultTheme,
  selectedLayout: layoutMap.defaultLayout,
  originalLayout: layoutMap.defaultLayout,
  contents: []
};

let initialState = {
  draggable: false,
  storeOwnersLoading: false,
  loadingConfiguration: false,
  notifications: {},
  layouts: [],
  themes: [],
  palettes: [],
  storeOwners: [],
  screens: [],
  contents: [],
  selectedStoreOwnerId: 1,
  selectedStoreId: 1,
  selectedScreenId: 1,
  currentScreenModel: { ...defaultScreenModel }
};

var adminStateReducer = function(state = initialState, action) {
  switch (action.type) {
    case ActionType.STORE_OWNERS_LOADING:
      return {
        ...state,
        storeOwnersLoading: true
      };
    case ActionType.STORE_OWNERS_FETCHED:
      return {
        storeOwnersLoading: false,
        selectedStoreId: 1,
        selectedScreenId: 1,
        stores: [...action.payload.stores],
        screens: [...action.payload.screens]
      };
    case ActionType.SET_SELECTED_STORE:
      return {
        ...state,
        selectedStoreId: action.payload.storeId
      };
    case ActionType.SET_SELECTED_SCREEN:
      return {
        ...state,
        selectedScreenId: action.payload.screenId
      };
    case ActionType.THEME_CONFIGURATION_CHANGED:
      return {
        ...state,
        selectedTheme: themeMap[action.payload]
      };
    case ActionType.LAYOUT_CONFIGURATION_CHANGED:
      return {
        ...state,
        selectedLayout: action.payload //TODO: Change to map lookup
      };
    case ActionType.AREA_TYPE_CHANGED:
      let _id = action.payload.dockContentId.slice(-1);
      let oldDockContent = state.contents.find(
        item => item.dockContentId === "_" + _id
      );

      oldDockContent.contentType =
        action.payload.selectedItem === DataType.Advertise
          ? CompomentType.CAROUSEL
          : CompomentType.VIRTUALIZEDTABLE;
      oldDockContent.contentTitle = action.payload.selectedItem.toUpperCase();
      oldDockContent.dataType = action.payload.selectedItem;

      let newState = {
        ...state,
        contents: [
          ...state.contents.filter(item => item.dockContentId !== "_" + _id),
          ...[oldDockContent]
        ]
      };
      return newState;
    case ActionType.PUSHING_CONTENTS_DATA_TO_DB:
      return {
        ...state,
        notifications: action.payload
      };
    case ActionType.LAYOUT_CHANGED:
      return {
        ...state,
        layout: action.payload.layouts
      };
    case ActionType.DRAGGABLE_BUTTON_CLICKED:
      return {
        ...state,
        draggable: !state.draggable
      };
    //
    default: {
      return state;
    }
  }
};

export default combineReducers({
  state: adminStateReducer
});
