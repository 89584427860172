import { useEffect } from "react";
import axios from "axios";
import * as appFunc from "../../App.Func";
import { getApolloData, getMixedData } from "./service";
import { useApolloClient } from "@apollo/client";
import { GET_INVENTORY } from "../../apollo/inventory.query";
import {useLocation} from "react-router-dom";

const jbStoresRec = [6049, 6050, 6051]; // store ids
const jbStoresMed = [6194, 6196, 6195]; // store ids
const maxDataAge = 600000;

let queryRef;

const fetchInventoryAPI = async (storeId, setInventoryData, selectedCategory) => {
    if (selectedCategory?.length) {
        const params = {
            categories:
                typeof selectedCategory === "string" ? [selectedCategory] : selectedCategory,
            dateTime: new Date().toString(),
        };
        if (jbStoresRec.includes(storeId)) {
            const { data: resultRec } = await axios.get(
                `${appFunc.getApiUrl()}/Inventory/${storeId}/Categories`,
                {
                    params,
                }
            );
            const { data: resultMed } = await axios.get(
                `${appFunc.getApiUrl()}/Inventory/${
                    jbStoresMed[jbStoresRec.indexOf(storeId)]
                }/Categories`,
                {
                    params,
                }
            );

            const mixedData = getMixedData(resultRec, resultMed);
            setInventoryData(massageData(mixedData));
        } else {
            const url = `/Inventory/${storeId}/Categories`;
            const { data } = await axios.get(
                `${appFunc.getApiUrl()}/Inventory/${storeId}/Categories`,
                {
                    params,
                }
            );
            setInventoryData(massageData(data));

            try {
              localStorage.setItem(
                url + "/" + selectedCategory.join("-"),
                JSON.stringify({ data, timeAquired: new Date() })
              );
            } catch (error) {
              console.log('local storage error');
              console.log(error);
              console.log(data);
            }
        }
    }
};

const fetchInventoryApollo = async (storeId, setInventoryData, selectedCategory, client) => {
  selectedCategory = selectedCategory.map((category) => {
    return [category, category.toLowerCase()];
  });
    const variables = {
        StoreId: storeId,
        Category: selectedCategory.join(', '),
    }
    if(selectedCategory?.length){
        if (jbStoresRec.includes(storeId)) {
            queryRef = client
              .watchQuery({
                query: GET_INVENTORY,
                variables,
                pollInterval: maxDataAge,
              })
              .subscribe({
                next: async ({ data: resultRec }) => {
                  const { data: resultMed } = await client.query({
                    query: GET_INVENTORY,
                    variables: {
                      ...variables,
                      StoreId: jbStoresMed[jbStoresRec.indexOf(storeId)],
                    },
                  });
                  const mixedData = getMixedData(
                    resultRec.Inventories,
                    resultMed.Inventories
                  );
                  setInventoryData(massageData(getApolloData(mixedData)));
                },
                error: (e) => console.error(e),
              });
        } else {
            const url = `/Inventory/${storeId}/Categories`;
            queryRef = client
              .watchQuery({
                query: GET_INVENTORY,
                variables,
                pollInterval: maxDataAge,
              })
              .subscribe({
                next: ({ data }) => {
                    const preparedData = getApolloData(data.Inventories);
                    setInventoryData(massageData(preparedData));
                    localStorage.setItem(
                        url + "/" + selectedCategory.join("-"),
                        JSON.stringify({ data: preparedData, timeAquired: new Date() })
                    );
                },
                error: (e) => console.error(e),
              });
            
        }
    }
}

const defineApiVersion = (isApolloRoute, storeId, setInventoryData, selectedCategory, client) => {
    if(isApolloRoute){
        fetchInventoryApollo(storeId, setInventoryData, selectedCategory, client)
    }else {
        fetchInventoryAPI(storeId,setInventoryData,selectedCategory);
    }
}

const massageData = (data) => {
  // console.log('running massageData');
  // console.log(data);

  // JTT - 2023-11-23
  // we want to pull Tac value from json and add it to the data object
  for (let i = 0; i < data.length; i++) {
    const item = data[i];

    if (item.CannabinoidsJSON) {
      const json = JSON.parse(item.CannabinoidsJSON);

      const tacItem = json.CannabinoidItems.find((el) => el.CannabinoidType === "Tac");
      item.Tac = tacItem ? tacItem.Value : null;

      const totalCannabinoidsItem = json.CannabinoidItems.find((el) => el.CannabinoidType === "TotalCannabinoids");
      item.TotalCannabinoids = totalCannabinoidsItem ? totalCannabinoidsItem.Value : null;

      const totalTerpenesItem = json.CannabinoidItems.find((el) => el.CannabinoidType === "TotalTerpenes");
      item.TotalTerpenes = totalTerpenesItem ? totalTerpenesItem.Value : null;
    }
    if (item.OtherDataJSON) {
      const otherDataJson = JSON.parse(item.OtherDataJSON);

      if (
        otherDataJson &&
        otherDataJson.Tags &&
        otherDataJson.Tags.length > 0
      ) {
        item.Tags = otherDataJson.Tags;
        // console.log(item.Tags);
      } else {
        item.Tags = [];
      }
    }
  }

  return data;
}
const useInventory = (screenId, storeId, selectedCategory, setInventoryData) => {
    const client = useApolloClient();
    const location = useLocation();
    let timer;

    // console.log('useInventory');
    
    const isApolloRoute = location.pathname.includes('/v2/');

    const retrieveInventory = (url) => {
        const storage = JSON.parse(localStorage.getItem(url));

        if (storage && storage.data) {
            setInventoryData(storage.data);
            if (new Date() - new Date(storage.timeAquired) > maxDataAge) {
                defineApiVersion(isApolloRoute, storeId, setInventoryData, selectedCategory, client)
            }
        } else {
            defineApiVersion(isApolloRoute, storeId, setInventoryData, selectedCategory, client)
        }
        if(isApolloRoute){
            defineApiVersion(isApolloRoute, storeId, setInventoryData, selectedCategory, client)
        }else {
            timer = setInterval(() => {
                defineApiVersion(isApolloRoute, storeId, setInventoryData, selectedCategory, client)
            }, maxDataAge);
        }
    };

    useEffect(() => {
        clearInterval(timer);
        const url = `/Inventory/${storeId}/Categories`;
        retrieveInventory(url + "/" + selectedCategory.join("-"));
        return () => {
            clearInterval(timer);
            if(isApolloRoute){
                queryRef.unsubscribe();
            }
        };
    }, [screenId, selectedCategory]);
    return fetchInventoryAPI;
};

export default useInventory;
