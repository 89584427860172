import themeBase from "./themeBase";
import palette from "../palettes/defaultPalettes/midnightChristmas";

const factory = (p = palette, fontSizeCalculator) => {
  const base = themeBase(p);

  return {
    id: "midnightChristmas",
    label: "Midnight Christmas",
    main: {
      ...base.main,
      textTransform: "uppercase",
      fontSize: fontSizeCalculator(16),
      // display: "inline-block",
    },
    container: {
      ...base.container,
      borderWidth: "1px 0px 1px 1px",
      borderStyle: "solid",
      borderColor: p.grid,
    },
    menu: {
      header: {
        wrapper: {
          ...base.menu.header.wrapper,
          backgroundColor: p.header,
          textAlign: "left",
        },
        preHeader: {
          width: "35px",
          border: `2px solid ${p.textHeader}`,
          display: "inline-block",
          marginLeft: "20px",
          marginBottom: "11px",
        },
        style: {
          ...base.menu.header.style,
          fontFamily: "Bevan",
          fontSize: fontSizeCalculator(50),
          letterSpacing: "2.8px",
          display: "inline-block",
        },
      },
      table: {
        style: {
          ...base.menu.table.style,
          fontFamily: "Open Sans",
          borderCollapse: "unset",
        },
        header: {
          style: {
            ...base.menu.table.header.style,
            padding: "35px 15px 25px",
            borderWidth: "0px 1px 1px 0px",
            borderStyle: "solid",
            borderColor: p.grid,
            fontSize: fontSizeCalculator(30),
          },
          overrides: {
            ...base.menu.table.header.overrides,
          },
        },
        data: {
          style: {
            ...base.menu.table.data.style,
            borderWidth: "0px 1px 1px 0px",
            borderStyle: "solid",
            borderColor: p.grid,
            fontSize: fontSizeCalculator(25),
          },
          overrides: {
            ...base.menu.table.data.overrides,
          },
        },
      },
    },
    specialSettings: {
      ...base.specialSettings,
    },
    advertisement: {
      ...base.advertisement,
    },
    content: {
      ...base.content,
    },
  };
};

export default factory;
