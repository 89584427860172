import defaultFactory from "./defaultPalettes/defaultPalette";
import bananaRepublic from "./defaultPalettes/bananaRepublic";
// import blueDream from "./defaultPalettes/blueDream";
// import dinerPlacemat from "./defaultPalettes/dinerPlacemat";
// import longTallSally from "./defaultPalettes/longTallSally";
// import longTallShelly from "./defaultPalettes/longTallShelly";
import midnightChristmas from "./defaultPalettes/midnightChristmas";
// import midnightSimple from "./defaultPalettes/midnightSimple";
// import midnightVertigo from "./defaultPalettes/midnightVertigo";
// import organicMidnight from "./defaultPalettes/organicMidnight";
// import organicMorning from "./defaultPalettes/organicMorning";
import organicNoon from "./defaultPalettes/organicNoon";
import pastelFade from "./defaultPalettes/pastelFade";
import tanPlan from "./defaultPalettes/tanPlan";
import greenside from "./defaultPalettes/greenside";
import oasis from "./defaultPalettes/oasis";
import smyth from "./defaultPalettes/smyth";
import universalHerbs from "./defaultPalettes/universalHerbs";
import clinicCannabis from "./defaultPalettes/clinicCannabis";
import mosquitoNet from "./defaultPalettes/mosquitoNet";
import spaceExploration from "./defaultPalettes/spaceExploration";
import clearCut from "./defaultPalettes/clearCut";
import deltaOne from "./defaultPalettes/deltaOne";
import deltaTwo from "./defaultPalettes/deltaTwo";
// import twoFace from "./defaultPalettes/twoFace";

export const map = {
  default: defaultFactory,
  bananaRepublic,
  // blueDream,
  // dinerPlacemat,
  // longTallSally,
  // longTallShelly,
  midnightChristmas,
  // midnightSimple,
  // midnightVertigo,
  // organicMidnight,
  // organicMorning,
  organicNoon,
  pastelFade,
  tanPlan,
  greenside,
  universalHerbs,
  clinicCannabis,
  mosquitoNet,
  spaceExploration,
  oasis,
  smyth,
  clearCut,
  deltaOne,
  deltaTwo,
  // twoFace
};

export const paletteOptions = () => {
  return Object.keys(map).map((key) => {
    return {
      id: map[key].id,
      label: map[key].label,
    };
  });
};

const factory = (palette) => {
  return map[palette];
};

export default factory;
