const imageScreenStyle = {
  container: {
    height: "100vh",
    width: "100vw",
    // backgroundColor: "#2E665C", //For Testing
    color: "#FFFFFF",
  },
  topBar: {
    height: "72px",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    maxHeight: "49px",
    maxWidth: "49px",
    marginRight: "40px",
  },
  category: {
    fontFamily: "Roboto Medium",
    fontSize: "35px",
    letterSpacing: "5px",
  },
  product: {
    height: "calc(100vh - 72px)",
  },
  productHorizontal: {
    display: "flex",
  },
  separationVertical: {
    height: "50%",
  },
  separationHorizontal: {
    width: "50%",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    height: "810px",
    width: "810px",
    borderRadius: "15px",
  },
  imageVertical: {
    marginTop: "50px",
  },
  productInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  productTitle: {
    height: "300px",
    maxWidth: "750px",
    lineHeight: "1.6",
  },
  productMarginVertical: {
    marginTop: "20px",
  },
  productMarginHorizontal: {
    marginTop: "100px",
  },
  productName: {
    fontFamily: "Roboto Medium",
    fontSize: "60px",
  },
  productBrand: {
    fontFamily: "Roboto Light Italic",
    fontSize: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  brandIcon: {
    height: "60px",
    width: "60px",
    marginRight: "40px",
  },
  productDominance: {
    fontFamily: "Roboto Light Italic",
    fontSize: "35px",
  },
  productDetails: {
    position: "absolute",
    bottom: "0",
    marginBottom: "80px",
  },
  highlightContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "60px",
  },
  productPriceContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderRadius: "20px",
    width: "458px",
    height: "136px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  productPrice: {
    fontFamily: "Roboto Medium",
    fontSize: "80px",
    width: "50%",
    textAlign: "left",
  },
  pricePrefix: {
    fontFamily: "Roboto Medium",
    fontSize: "45px",
    width: "50%",
    color: "#C5CF60",
    paddingTop: "30px",
  },
  productExtraInfo1: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  productExtraInfo2: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
  extraTitle: {
    fontFamily: "Roboto Medium",
    fontSize: "60px",
    color: "#C5CF60",
  },
  extra: {
    fontFamily: "Roboto Medium",
    fontSize: "50px",
    minWidth: "190px",
  },
  bar: {
    border: "2px solid #C5CF60",
    margin: "15px 150px"
  },
};

export default imageScreenStyle;