const palette = {
  id: "spaceExploration",
  label: "Space Exploration",
  backgroundColor: "white",
  header: "rgba(0, 0, 0, 0)",
  table: "rgba(41, 41, 41, 0.4)",
  tableHeader: "black",
  grid: "rgb(240, 240, 240)", // Table Grid Color
  tableHighlight: "rgba(62, 62, 62, 0.2)",
  textHeader: "white",
  textTable: "white",
  textTableHeader: "white",
};

export default palette;
