import themeBase from "./themeBase";
import palette from "../palettes/defaultPalettes/greenside";

export const defaultTheme = (p = palette, fontSizeCalculator = () => {}) => {
  const base = themeBase(p);
  return {
    id: "bigSmall",
    label: "Big Small",
    main: {
      ...base.main,
      textTransform: "uppercase",
      fontSize: fontSizeCalculator(16),
      // display: "inline-block",
    },
    container: {
      ...base.container,
      borderWidth: "1px 1px 1px 1px",
      borderStyle: "solid",
      borderColor: p.grid,
      // height: "100%",
      // backgroundColor: p.table,
    },
    menu: {
      header: {
        wrapper: {
          ...base.menu.header.wrapper,
          // backgroundColor: p.primary,
          // textAlign: "left",
        },
        // preHeader: {
        //   width: "35px",
        //   border: `2px solid ${p.secondary}`,
        //   display: "inline-block",
        //   marginLeft: "20px",
        //   marginBottom: "11px",
        // },
        style: {
          ...base.menu.header.style,
          fontFamily: "Montserrat Bold", //TODO: import font
          fontSize: fontSizeCalculator(50),
          // letterSpacing: "2.8px",
          fontWeight: "bold",
          display: "inline-block",
          padding: "0px",
        },
      },
      table: {
        style: {
          ...base.menu.table.style,
          // height: "100%",
          fontFamily: "Open Sans",
          borderCollapse: "unset",
        },
        header: {
          style: {
            ...base.menu.table.header.style,
            fontFamily: "Roboto Mono",
            padding: "20px 15px 10px",
            borderWidth: "0px 0px 1px 0px",
            borderStyle: "solid",
            borderColor: p.grid,
            fontSize: fontSizeCalculator(26),
            fontWeight: "normal",
          },
          overrides: {
            ...base.menu.table.header.overrides,
          },
        },
        data: {
          style: {
            ...base.menu.table.data.style,
            fontSize: fontSizeCalculator(18),
            padding: "12px 10px",
            fontFamily: "Roboto",
            borderWidth: "0px 0px 1px 0px",
            borderStyle: "solid",
            borderColor: p.grid,
          },
          overrides: {
            ...base.menu.table.data.overrides,
            "0": {
              display: "inlinie",
            },
          },
        },
      },
    },
    specialSettings: {
      ...base.specialSettings,
    },
    advertisement: {
      ...base.advertisement,
    },
    content: {
      ...base.content,
    },
  };
};

export default defaultTheme;
