const textScreenStyle = {
  pageContainerVert: {
    height: "calc(100vh - 40px)",
    width: "calc(100vw - 80px)",
    padding: "20px 40px",
    // backgroundColor: "green",
  },
  categoryContainerHalfVert: {
    height: "calc(50% - 40px)",
    padding: "20px 0px",
  },
  categoryContainerThirdVert: {
    height: "calc(33.33% - 40px)",
    padding: "20px 0px",
  },
  pageContainerHor: {
    height: "calc(100vh - 80px)",
    width: "calc(100vw - 40px)",
    padding: "40px 20px",
    display: "flex",
    flexFlow: "column wrap",
    // backgroundColor: "green",
  },
  categoryContainerHalfHor: {
    width: "calc(50% - 40px)",
    padding: "0px 20px",
    height: "100%",
  },
  categoryContainerThirdHor: {
    width: "calc(33.33% - 40px)",
    padding: "0px 20px",
    height: "100%",
  },
  categoryContainerThirdHorHalf: {
    width: "calc(33.33% - 40px)",
    height: "50%",
    padding: "0px 20px",
  },
};

export default textScreenStyle;