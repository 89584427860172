import themeBase from "./themeBase";
import defaultPalette from "../palettes/defaultPalettes/defaultPalette";

export const defaultTheme = (
  palette = defaultPalette,
  fontSizeCalculator = () => {}
) => {
  const base = themeBase(palette);
  return {
    id: "default",
    label: "Default",
    main: {
      ...base.main,
      fontFamily: "Open Sans",
      textTransform: "uppercase",
      fontSize: fontSizeCalculator(16),
      // display: "inline-block"
    },
    container: {
      ...base.container,
      borderWidth: "1px 0px 1px 1px",
      borderStyle: "solid",
      borderColor: palette.grid,
    },
    menu: {
      header: {
        wrapper: {
          ...base.menu.header.wrapper,
        },
        style: {
          ...base.menu.header.style,
          textAlign: "center",
          fontSize: fontSizeCalculator(50),
          letterSpacing: "6.4px",
          clipPath:
            "polygon(0% 0%, 100% 0%, 100% 85%, 55% 85%, 50% 100%, 45% 85%, 0% 85%)",
          marginBottom: "15px",
          padding: "10px 20px 30px",
        },
      },
      table: {
        ...base.menu.table,
        style: {
          ...base.menu.table.style,
          borderCollapse: "unset",
        },
        header: {
          style: {
            ...base.menu.table.header.style,
            padding: "35px 15px 25px",
            borderWidth: "0px 1px 1px 0px",
            borderStyle: "solid",
            borderColor: palette.grid,
            fontSize: fontSizeCalculator(28),
          },
          overrides: {
            ...base.menu.table.header.overrides,
          },
        },
        data: {
          style: {
            ...base.menu.table.data.style,
            borderWidth: "0px 1px 1px 0px",
            borderStyle: "solid",
            borderColor: palette.grid,
            fontSize: fontSizeCalculator(20),
          },
          overrides: {
            ...base.menu.table.data.overrides,
          },
        },
      },
    },
    specialSettings: {
      ...base.specialSettings,
    },
    advertisement: {
      ...base.advertisement,
    },
    content: {
      ...base.content,
    },
  };
};

export default defaultTheme;
