const palette = {
  id: "universalHerbs",
  label: "Universal Herbs",
  backgroundColor: "white",
  header: "white",
  table: "rgb(100, 46, 144, 0.7)",
  tableHeader: "rgb(100, 46, 144)",
  grid: "white", // Table Grid Color
  tableHighlight: "rgb(100, 46, 144, 0.5)", //?
  textHeader: "black",
  textTable: "white",
  textTableHeader: "white",
};

export default palette;
