const palette = {
  id: "bananaRepublic",
  label: "Banana Republic",
  backgroundColor: "white",
  header: "#FAC64B",
  table: "#292929",
  tableHeader: "#292929",
  grid: "#BA834D", // Table Grid Color
  tableHighlight: "#3E3E3E",
  textHeader: "#292929",
  textTable: "white",
  textTableHeader: "white",
};

export default palette;
