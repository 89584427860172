import themeBase from "./themeBase";
import palette from "../palettes/defaultPalettes/tanPlan";

const factory = (p = palette, fontSizeCalculator) => {
  const base = themeBase(p);

  return {
    id: "default",
    label: "Default",
    main: {
      ...base.main,
      // position: "relative",
      // zIndex: "-2",
      fontSize: fontSizeCalculator(16),
    },
    container: {
      ...base.container,
      // border: `2px solid ${p.grid}`,
      backgroundColor: p.table,
      // padding: "60px 5px 40px",
      position: "relative",
      // top: "-20px",
      // zIndex: "-1",
    },
    menu: {
      header: {
        wrapper: {
          ...base.menu.header.wrapper,
          padding: "0px 0px 10px",
        },
        preHeader: {
          // width: "35px",
          // top: "10px",
          // border: `2px solid black`,
          // position: "fixed",
          // // display: "inline-block",
          // marginLeft: "20px",
          // marginBottom: "11px",
        },
        style: {
          ...base.menu.header.style,
          textAlign: "center",
          fontFamily: "Averia Serif Libre",
          fontStyle: "italic",
          fontSize: fontSizeCalculator(64),
          textTransform: "capitalize",
          padding: "5px 30px 5px",
          clipPath:
            "polygon(85% 0%, 82% 50%, 85% 100%, 15% 100%, 18% 50%, 15% 0%)",
        },
      },
      table: {
        style: {
          ...base.menu.table.style,
          borderCollapse: "unset",
          // padding: "5px",
        },
        // headerRow: {
        //   fontFamily: "Averia Serif Libre",
        //   textTransform: "capitalize",
        //   fontSize: fontSizeCalculator(24),
        // },
        header: {
          style: {
            ...base.menu.table.header.style,
            fontFamily: "Averia Serif Libre",
            textTransform: "capitalize",
            fontSize: fontSizeCalculator(26),
            // borderLeft: `1px solid ${p.grid}`,
            padding: "20px 15px",
          },
          overrides: {
            "0": {
              ...base.menu.table.header.overrides["0"],
              borderLeft: `none`,
            },
          },
        },
        data: {
          style: {
            ...base.menu.table.data.style,
            borderLeft: `1px solid ${p.grid}`,
            textTransform: "uppercase",
            padding: "15px 15px 15px",
            fontSize: fontSizeCalculator(18),
          },
          overrides: {
            "0": {
              ...base.menu.table.data.overrides["0"],
              borderLeft: `none`,
            },
          },
        },
      },
    },
    specialSettings: {
      ...base.specialSettings,
    },
    advertisement: {
      ...base.advertisement,
    },
    content: {
      ...base.content,
    },
  };
};

export default factory;
