import themeBase from "./themeBase";
import palette from "../palettes/defaultPalettes/deltaOne";

const factory = (p = palette, fontSizeCalculator) => {
  const base = themeBase(p);

  // console.log("base: ", base);

  return {
    id: "smyth",
    label: "Smyth",
    main: {
      ...base.main,
      textTransform: "uppercase",
      fontSize: fontSizeCalculator(16),
    },
    container: {
      ...base.container,
    },
    menu: {
      header: {
        wrapper: {
          ...base.menu.header.wrapper,
        },
        style: {
          ...base.menu.header.style,
          fontFamily: "Montserrat Bold", //TODO: import font
          fontSize: fontSizeCalculator(80),
          fontWeight: "bold",
          display: "inline-block",
          padding: "0px",
        },
      },
      table: {
        style: {
          ...base.menu.table.style,
          fontFamily: "Open Sans",
          borderCollapse: "unset",
        },
        header: {
          style: {
            ...base.menu.table.header.style,
            fontFamily: "Roboto",
            padding: "30px 15px 20px",
            borderWidth: "0px 0px 0px 0px",
            borderStyle: "solid",
            borderColor: p.grid,
            fontSize: fontSizeCalculator(50),
            fontWeight: "bold",
          },
          overrides: {
            ...base.menu.table.header.overrides,
            "0": {
              textAlign: "center",
            },
          },
        },
        data: {
          style: {
            ...base.menu.table.data.style,
            fontSize: fontSizeCalculator(30),
            fontFamily: "Roboto",
            fontWeight: "bold",
            borderWidth: "0px 0px 1px 0px",
            borderStyle: "solid",
            borderColor: p.grid,
            padding: "25px 15px",
            verticalAlign: "top",
            textAlign: "left"
          },
          overrides: {
            ...base.menu.table.data.overrides,
            "0": {
              textAlign: "left",
              justifyContent: "left",
              // display: "flex",
            },
          },
        },
      },
    },
    specialSettings: {
      ...base.specialSettings,
    },
    advertisement: {
      ...base.advertisement,
    },
    content: {
      ...base.content,
    },
  };
};

export default factory;
