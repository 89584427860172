export const themeBase = (palette) => {
  return {
    main: {
      // backgroundColor: palette.backgroundColor,
      // display: "inline-block",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    container: {
      overflow: "scroll",
    },
    menu: {
      header: {
        wrapper: {
          // minHeight: "80px",
        },
        style: {
          backgroundColor: palette.header,
          color: palette.textHeader,
          padding: "30px",
        },
      },
      table: {
        style: {
          // display: "grid",
          gridTemplateColumns: "minmax(240px, 600px) auto",
          backgroundColor: palette.table,
          color: palette.textTable,
          position: "relative",
          top: "0px",
          // width: "100%",
          // height: "100%",
          // display: "block",
          // overflow: "scroll",
        },
        header: {
          style: {
            fontWeight: "bold",
            textAlign: "center",
            position: "sticky",
            top: "0",
            backgroundColor: palette.tableHeader,
            color: palette.textTableHeader,
          },
          overrides: {
            // "0": {
            //   textAlign: "left",
            // },
          },
        },
        data: {
          style: {
            color: palette.textTable,
            justifyContent: "center",
            lineHeight: "normal",
            letterSpacing: "normal",
            // display: "flex",
            // whiteSpace: "nowrap",
            // overflow: "hidden",
            // textOverflow: "ellipsis",
            alignItems: "center",
            textAlign: "center",
            padding: "20px",
          },
          overrides: {
            "0": {
              textAlign: "center",
              justifyContent: "center",
              // display: "flex",
              // whiteSpace: "nowrap",
              // overflow: "hidden",
              // textOverflow: "ellipsis",
            },
            Image: {
              padding: "5px",
            },
            Dominance: {
              // display: "flex",
            },
            Weight: {
              textTransform: "lowercase",
            },
            Thc: {
              textTransform: "lowercase",
            },
            Cbd: {
              textTransform: "lowercase",
            }
          },
        },
      },
    },
    specialSettings: {
      highlight: {
        background: `${palette.tableHighlight}`,
      },
    },
    advertisement: {},
    content: {},
  };
};

export default themeBase;
