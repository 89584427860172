import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
// import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import { object, string, array, func } from "prop-types";
import ScreenContext from "../Screen/ScreenContext";
import "./Branding.css";

const propTypes = {
  background: object,
  title: string,
  position: object,
  socialIcons: array,
  logo: string,
  onSocialIconClicked: func,
};

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    flexGrow: 1,
  }),

  menuButton: {
    marginRight: theme.spacing(2),
    backgroundColor: "#000",
  },
  title: {
    flexGrow: 1,
  },
}));

const Branding = (props) => {
  const {
    uploadImage,
    socialMediaColor,
    onBrandingChange,
    // brandingSetting,
  } = props;
  const classes = useStyles(props);

  const [openSettings, setOpenSettings] = useState(false);
  const [brandingColor, setBrandingColor] = useState(
    socialMediaColor ? socialMediaColor : "black"
  );

  const brandingColors = ["white", "black"];

  // Handles open and close for dialog
  const handleClickOpen = () => {
    setOpenSettings(true);
  };
  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  const handleSetBranding = () => {
    const newBranding = brandingColor;
    onBrandingChange(newBranding);
    handleCloseSettings();
  };

  const handleColorChange = (event) => {
    setBrandingColor(brandingColors[event.target.value]);
  };

  const onUploadButtonClick = () => {
    uploadImage("logos");
  };

  return (
    <ScreenContext.Consumer>
      {({
        logo,
        name,
        socialMediaHandle,
        socialMediaColor,
        editMode,
        onMediaHandleChange,
        onLogoChange,
        brandingSetting,
        socialSetting,
      }) => (
        <div className={classes.root} position="static">
          <Toolbar
            style={{
              display: brandingSetting ? "" : "none",
              // minHeight: "100px",
              height: "125px",
            }}
          >
            <EditIcon
              style={{
                color: "#ff0dbf", // This color works for now for visibility
                mixBlendMode: "difference",
                top: "0",
                right: "0",
                cursor: "pointer",
                zIndex: "10",
                position: "absolute",
                display: editMode ? "" : "none",
              }}
              onClick={handleClickOpen}
            ></EditIcon>
            <Dialog
              open={openSettings}
              onClose={handleCloseSettings}
              fullWidth={true}
              maxWidth="lg"
            >
              <DialogTitle id="area-dialog-title">Area Settings</DialogTitle>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel>Choose Branding Color</InputLabel>
                      <Select
                        style={{ width: "200px" }}
                        value={brandingColors.indexOf(brandingColor)}
                        onChange={handleColorChange}
                      >
                        {brandingColors.map((color, index) => (
                          <MenuItem value={index} key={index}>{color}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      {/* <InputLabel>Choose Logo</InputLabel> */}
                      <label for="linput">Choose Logo</label>
                      <input
                        id="linput"
                        type="file"
                        style={{
                          display: editMode ? "" : "none",
                        }}
                        onChange={onLogoChange}
                      />
                      <button
                        onClick={onUploadButtonClick}
                        style={{
                          display: editMode ? "" : "none",
                        }}
                      >
                        Upload Logo
                      </button>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSetBranding}>Set Branding</Button>
              </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={4} style={{ textAlign: "left" }}>
              <img
                alt="logo"
                src={logo}
                style={{
                  width: "auto",
                  height: "auto",
                  maxHeight: "125px",
                  marginTop: "15px",
                  position: "absolute",
                  top: 0,
                }} // TODO: this goes away when actual logos load
              />
              {/* <input
                type="file"
                style={{
                  display: editMode ? "" : "none",
                }}
                onChange={onLogoChange}
              />
              <button
                onClick={onUploadButtonClick}
                style={{
                  display: editMode ? "" : "none",
                }}
              >
                Upload
              </button> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" className={classes.title}>
                {name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                color: socialMediaColor ? socialMediaColor : "black",
                display: socialSetting !== false ? "" : "none",
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={5}>
                  <Typography
                    variant="h5"
                    component="p"
                    style={{ paddingTop: "34px" }}
                  >
                    <strong>FOLLOW</strong>
                    <TwitterIcon
                      style={{
                        width: "1.2em",
                        height: "1.2em",
                        marginLeft: "10px",
                        position: "relative",
                        top: 4,
                      }}
                    />
                    <FacebookIcon
                      style={{
                        width: "1.2em",
                        height: "1.2em",
                        marginLeft: "10px",
                        position: "relative",
                        top: 4,
                      }}
                    />
                    <InstagramIcon
                      style={{
                        width: "1.2em",
                        height: "1.2em",
                        marginLeft: "10px",
                        position: "relative",
                        top: 4,
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="h6"
                    component="p"
                    style={{ paddingTop: "34px" }}
                  >
                    <strong>{socialMediaHandle}</strong>
                  </Typography>
                  <TextField
                    style={{
                      display: editMode ? "" : "none",
                    }}
                    helperText="Social Media Handle"
                    onChange={onMediaHandleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </div>
      )}
    </ScreenContext.Consumer>
  );
};

Branding.propTypes = propTypes;

export default Branding;
