import themeBase from "./themeBase";
import tabletPalette from "../palettes/defaultPalettes/tabletPalette";

export const defaultTheme = (
  palette = tabletPalette,
  fontSizeCalculator = () => {}
) => {
  const base = themeBase(palette);
  return {
    id: "tablet",
    label: "Tablet",
    main: {
      ...base.main,
      fontFamily: "Open Sans",
      textTransform: "uppercase",
      fontSize: fontSizeCalculator(16),
      // display: "inline-block"
    },
    container: {
      ...base.container,
      borderWidth: "1px 0px 1px 1px",
      borderStyle: "solid",
      borderColor: palette.grid,
      backgroundColor: palette.table,
    },
    menu: {
      header: {
        style: {
          ...base.menu.header.style,
          textAlign: "center",
          fontSize: fontSizeCalculator(60),
          letterSpacing: "6.4px",
          clipPath:
            "polygon(0% 0%, 100% 0%, 100% 85%, 55% 85%, 50% 100%, 45% 85%, 0% 85%)",
          marginBottom: "15px",
          padding: "10px 20px 30px",
        },
      },
      table: {
        ...base.menu.table,
        style: {
          ...base.menu.table.style,
          borderCollapse: "unset",
          // border: `1px solid ${palette.grid}`,
        },
        header: {
          style: {
            ...base.menu.table.header.style,
            padding: "35px 15px 25px",
            // border: `1px solid ${palette.grid}`,
            borderWidth: "0px 1px 1px 0px",
            borderStyle: "solid",
            borderColor: palette.grid,
            fontSize: fontSizeCalculator(20),
          },
          overrides: {
            ...base.menu.table.header.overrides,
          },
        },
        data: {
          style: {
            ...base.menu.table.data.style,
            // border: `1px solid ${palette.grid}`,
            borderWidth: "0px 1px 1px 0px",
            borderStyle: "solid",
            borderColor: palette.grid,
            fontSize: fontSizeCalculator(16),
          },
          overrides: {
            ...base.menu.table.data.overrides,
            "0": {
              textAlign: "left",
            },
          },
        },
      },
    },
    specialSettings: {
      ...base.specialSettings,
    },
    advertisement: {
      ...base.advertisement,
    },
    content: {
      ...base.content,
    },
  };
};

export default defaultTheme;
