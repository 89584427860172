const midnightChristmas = {
  id: "midnightChristmas",
  label: "Midnight Christmas",
  backgroundColor: "#000000",
  header: "#82CA9C", //#F26D7D
  table: "#000000",
  tableHeader: "#000000",
  grid: "#BA834D", // Table Grid Color
  tableHighlight: "#3E3E3E",
  textHeader: "black",
  textTable: "white",
  textTableHeader: "white",
};

export default midnightChristmas;
