// import edible from "./edibles";
// import extract from "./extracts";
// import flower from "./flower";
// import joint from "./joints";
// import vape from "./vapes";
import columns from "./columns";

// const map = {
//   edible,
//   extract,
//   flower,
//   joint,
//   vape,
// };

const factory = (area) => {
  // console.log('template factory');
  // console.log(area.columns);
  let cols = columns.filter((column) =>
    !!area.columns
      ? area.columns.includes(column.field)
      : column.default.includes(area.category)
  );
  // console.log(cols);

  cols = area.columns
    ? area.columns
        .map((columnField) =>
          cols.find((column) => column.field === columnField)
        )
        .filter((column) => column)
    : cols;

  return { columns: cols };
};

export default factory;
