import { gql } from "@apollo/client";

export const GET_INVENTORY = gql`
  query InventoryQuery($StoreId: Int!, $Category: String!) {
    Inventories(StoreId: $StoreId, Category: $Category) {
      Id,
      BatchId,
      StoreOwnerName,
      StoreName,
      Latitude,
      Longitude,
      PointOfSaleName,
      SourceFile,
      StoreOwnerId,
      StoreId,
      ProductName,
      LicenseType,
      Category,
      Vendor,
      Brand,
      Type,
      Dominance,
      Strain,
      Quantity,
      Unit,
      CannaUnit,
      Sku,
      Price,
      PriceHalfGram,
      PriceGram,
      PriceTwoGram,
      PriceHalfEighth,
      PriceEighth,
      PriceQuarter,
      PriceHalfOunce,
      PriceOunce,
      Cost,
      Weight,
      Cbc,
      Cbd,
      Cbda,
      Cbn,
      Cbg,
      Thc,
      Thca,
      Description,
      PictureUrl,
      ThumbnailUrl,
      PricesJSON,
      CountSku,
      CannabinoidsJSON,
      OtherDataJSON,
      _OriginalJSON,
      Serving,
      TerpenesJSON,
      DataSource,
      SystemName,
      ProductId,
      PackagedDate
    }
  }
`;
