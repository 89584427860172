const palette = {
  id: "greenside",
  label: "Greenside",
  backgroundColor: "white",
  header: "rgba(0, 0, 0, 0)",
  table: "rgba(41, 41, 41, 0.4)",
  tableHeader: "white",
  grid: "rgb(240, 240, 240)", // Table Grid Color
  tableHighlight: "rgba(62, 62, 62, 0.2)",
  textHeader: "white",
  textTable: "white",
  textTableHeader: "#3E3E3E",
};

export default palette;
