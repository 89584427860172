const palette = {
    id: "smyth",
    label: "Smyth",
    backgroundColor: "white",
    header: "rgba(0, 0, 0, 0)",
    table: "rgba(41, 41, 41, 0.0)",
    tableHeader: "white",
    grid: "rgb(240, 240, 240)", // Table Grid Color
    tableHighlight: "rgba(62, 62, 62, 0)",
    textHeader: "black",
    textTable: "black",
    textTableHeader: "black",
  };
  
  export default palette;
  