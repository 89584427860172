import themeBase from "./themeBase";
import palette from "../palettes/defaultPalettes/greenside";

const factory = (p = palette, fontSizeCalculator) => {
  const base = themeBase(p);

  return {
    id: "greenside",
    label: "Greenside",
    main: {
      ...base.main,
      textTransform: "uppercase",
      fontSize: fontSizeCalculator(16),
      // display: "inline-block",
    },
    container: {
      ...base.container,
      borderWidth: "1px 1px 1px 1px",
      borderStyle: "solid",
      borderColor: p.grid,
    },
    menu: {
      header: {
        wrapper: {
          ...base.menu.header.wrapper,
          // backgroundColor: p.primary,
          // textAlign: "left",
        },
        // preHeader: {
        //   width: "35px",
        //   border: `2px solid ${p.secondary}`,
        //   display: "inline-block",
        //   marginLeft: "20px",
        //   marginBottom: "11px",
        // },
        style: {
          ...base.menu.header.style,
          fontFamily: "FF Mark Pro Bold", //TODO: import font
          fontSize: fontSizeCalculator(70),
          // letterSpacing: "2.8px",
          fontWeight: "bold",
          display: "inline-block",
          padding: "0px",
        },
      },
      table: {
        style: {
          ...base.menu.table.style,
          fontFamily: "Open Sans",
          borderCollapse: "unset",
        },
        header: {
          style: {
            ...base.menu.table.header.style,
            fontFamily: "FF Mark Pro Book",
            padding: "35px 15px 25px",
            borderWidth: "0px 0px 1px 0px",
            borderStyle: "solid",
            borderColor: p.grid,
            fontSize: fontSizeCalculator(34),
            fontWeight: "normal",
          },
          overrides: {
            ...base.menu.table.header.overrides,
          },
        },
        data: {
          style: {
            ...base.menu.table.data.style,
            fontSize: fontSizeCalculator(20),
            fontFamily: "FF Mark Pro Book",
            borderWidth: "0px 0px 3px 0px",
            borderStyle: "solid",
            borderColor: p.grid,
          },
          overrides: {
            ...base.menu.table.data.overrides,
          },
        },
      },
    },
    specialSettings: {
      ...base.specialSettings,
    },
    advertisement: {
      ...base.advertisement,
    },
    content: {
      ...base.content,
    },
  };
};

export default factory;
