import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Menu from "../Menu/Menu";
import themeFactory from "../../themes/themeFactory";
import getAreaConfig from "../Screen/ScreenActions";
import tabletPalette from "../../palettes/defaultPalettes/tabletPalette";

const TabletScreen = (props) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [flowerData, setFlowerData] = useState();
  const [specialtyFlowerData, setSpecialtyFlowerData] = useState();
  const [extractData, setExtractData] = useState();
  const [vapeData, setVapeData] = useState();
  const [jointData, setJointData] = useState();

  const retrieveData = async () => {
    const dataMapper = (dataPoint) => {
      return {
        ...dataPoint,
        Thc: dataPoint.Thc ? dataPoint.Thc : "--",
        Weight: dataPoint.Weight ? dataPoint.Weight + "g" : "--",
        Dominance: dataPoint.Dominance ? dataPoint.Dominance : "--",
        Price: dataPoint.Price,
        PriceGram: dataPoint.PriceGram,
        PriceTwoGram: dataPoint.PriceTwoGram,
        PriceHalfEighth: dataPoint.PriceHalfEighth,
        PriceQuarter: dataPoint.PriceQuarter,
        PriceHalfOunce: dataPoint.PriceHalfOunce,
        PriceOunce: dataPoint.PriceOunce,
      };
    };

    const flower = await getAreaConfig("77", {
      type: "menu",
      category: "Flower",
      columns: ["ProductName", "Dominance", "Thc", "custom"],
    });

    const flowerMapped = flower.data
      .filter((dataPoint) => {
        // Exceptions hard coded due to lack of product with weight of 1
        return (
          dataPoint.PriceGram &&
          (dataPoint.Weight === 1 ||
            dataPoint.ProductName === "Wedding Pie - 043 - 3.5g" ||
            dataPoint.ProductName === "Tangerine Medicine - 470 -3.5g" ||
            dataPoint.ProductName === "Layer Cake - 300 - 3.5g")
        );
      })
      .sort((a, b) => {
        if (a.PriceGram < b.PriceGram) return -1;
        if (a.PriceGram > b.PriceGram) return 1;
        return 0;
      })
      .map(dataMapper);
    setFlowerData({ ...flower, data: flowerMapped });

    const specialtyFlower = await getAreaConfig("77", {
      type: "menu",
      category: "Flower",
      columns: ["ProductName", "Dominance", "Thc", "Price"],
    });

    const specialtyFlowerMapped = flower.data
      .filter((dataPoint) => {
        return (
          JSON.parse(dataPoint.PricesJSON).PriceTiers[0].Name === "Default"
        );
      })
      .map(dataMapper);
    console.log(specialtyFlowerMapped);
    setSpecialtyFlowerData({
      ...specialtyFlower,
      data: specialtyFlowerMapped,
    });

    const extract = await getAreaConfig("77", {
      type: "menu",
      category: "Extract",
      columns: ["ProductName", "Dominance", "Thc", "Weight", "Price"],
    });

    const extractMapped = extract.data.map(dataMapper);
    setExtractData({ ...extract, data: extractMapped });

    const vape = await getAreaConfig("77", {
      type: "menu",
      category: "Vape",
      columns: ["ProductName", "Dominance", "Thc", "Weight", "Price"],
    });
    const vapeMapped = vape.data.map(dataMapper);
    setVapeData({ ...vape, data: vapeMapped });

    const joint = await getAreaConfig("77", {
      type: "menu",
      category: "Joint",
      columns: ["ProductName", "Dominance", "Thc", "Weight", "Price"],
    });
    const jointMapped = joint.data.map(dataMapper);
    setJointData({ ...joint, data: jointMapped });
  };

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  if (!flowerData && !extractData && !vapeData) {
    retrieveData();
  }

  const fontSizeCalculator = (fontSize) => {
    return `${fontSize}px`;
  };

  return (
    <div style={{ width: "100%" }}>
      <AppBar position="static" color="default">
        <Tabs
          value={currentTab}
          onChange={handleChange}
          aria-label="menu tabs"
          indicatorColor="primary"
          variant="fullWidth"
        >
          <Tab label="Flower" />
          <Tab label="Specialty Flower" />
          <Tab label="Extract" />
          <Tab label="Vape" />
          <Tab label="Joint" />
        </Tabs>
      </AppBar>
      <div
        hidden={currentTab !== 0}
        style={{ padding: "30px", height: "90vh" }}
      >
        {flowerData && (
          <Menu
            columns={flowerData.columns}
            data={flowerData.data}
            theme={themeFactory(
              "tablet",
              "Tablet",
              tabletPalette,
              fontSizeCalculator
            )}
            title="Flower"
            settings={{}}
          />
        )}
      </div>
      <div
        hidden={currentTab !== 1}
        style={{ padding: "30px", height: "90vh" }}
      >
        {specialtyFlowerData && (
          <Menu
            columns={specialtyFlowerData.columns}
            data={specialtyFlowerData.data}
            theme={themeFactory(
              "tablet",
              "Tablet",
              tabletPalette,
              fontSizeCalculator
            )}
            title="Specialty Flower"
            settings={{}}
          />
        )}
      </div>
      <div
        hidden={currentTab !== 2}
        style={{ padding: "30px", height: "90vh" }}
      >
        {extractData && (
          <Menu
            columns={extractData.columns}
            data={extractData.data}
            theme={themeFactory(
              "tablet",
              "Tablet",
              tabletPalette,
              fontSizeCalculator
            )}
            title="Extract"
            settings={{}}
          />
        )}
      </div>
      <div
        hidden={currentTab !== 3}
        style={{ padding: "30px", height: "90vh" }}
      >
        {vapeData && (
          <Menu
            columns={vapeData.columns}
            data={vapeData.data}
            theme={themeFactory(
              "tablet",
              "Tablet",
              tabletPalette,
              fontSizeCalculator
            )}
            title="Vape"
            settings={{}}
          />
        )}
      </div>
      <div
        hidden={currentTab !== 4}
        style={{ padding: "30px", height: "90vh" }}
      >
        {jointData && (
          <Menu
            columns={jointData.columns}
            data={jointData.data}
            theme={themeFactory(
              "tablet",
              "Tablet",
              tabletPalette,
              fontSizeCalculator
            )}
            title="Joint"
            settings={{}}
          />
        )}
      </div>
    </div>
  );
};

export default TabletScreen;
