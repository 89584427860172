import _ from 'lodash';

const inventoryHelper = {

  /**
   * @description Takes in an inventory list and does some filtering based on query-string params
   * @param {Array} data - full inventory data
   * @param {object} config - config object
   * @param {object} config.urlParams - object of the query-string params
   * @returns {string} propName - prop name for iconSets
   */
  qsFilter: function (data, config) {
    let myData = data;
    
    // console.log('Pre-Filtered Data', myData);
    // console.log('Config', config);
    
    const urlParams = config.urlParams || {};
    const settings = config.settings || {};

    const filterBy = urlParams.get("filterBy") || settings.filterBy;
    const filter = urlParams.get("filter") || settings.filter;

    const excludeBy = settings.excludeBy;
    const exclude = settings.exclude;

    const limit = urlParams.get("limit") || 9999;
    const limitBy = urlParams.get("limitBy");
    const limitBySort = urlParams.get("limitBySort");
    const limitBySortDirection = urlParams.get("limitBySortDirection") || "asc";

    const weightOperator = urlParams.get("weightOperator") || "gte";
    let weight = urlParams.get("weight");

    // if we're given a filterBy and filter then do that first
    // possible filters - Brand, Category, Dominance, ProductName, Strain or Type
    if (filterBy && filter) {
      const filterTerms = filter.split(",");

      // now we want to pull inventory where the defined property includes any of the filter terms
      if (filterBy === "Tags") {
        myData = myData.filter((item) => {
          return filterTerms.some((term) => {
            if (!item.Tags) return false;

            return item.Tags.some((tag) => {
              if (typeof tag === "string") {
                return tag.toLowerCase().includes(term.toLowerCase());
              } else if (typeof tag === "object") {
                return Object.values(tag).some((value) =>
                  String(value).toLowerCase().includes(term.toLowerCase())
                );
              }
              return false;
            });
          });
        });
      } else {
        myData = myData.filter((item) => {
          return filterTerms.some((term) => {
            return item[filterBy]
              ? item[filterBy].toLowerCase().includes(term.toLowerCase())
              : false;
          });
        });
      }
    }

    if (excludeBy && exclude) {
      const excludeTerms = exclude.split(",");

      // now we want to pull inventory where the defined property does not include any of the exclude terms
      if (excludeBy === "Tags") {
        myData = myData.filter((item) => {
          return !excludeTerms.some((term) => {
            if (!item.Tags) return false;

            return item.Tags.some((tag) => {
              if (typeof tag === "string") {
                return tag.toLowerCase().includes(term.toLowerCase());
              } else if (typeof tag === "object") {
                return Object.values(tag).some((value) =>
                  String(value).toLowerCase().includes(term.toLowerCase())
                );
              }
              return false;
            });
          });
        });
      } else {
        myData = myData.filter((item) => {
          return !excludeTerms.some((term) => {
            return item[excludeBy]
              ? item[excludeBy].toLowerCase().includes(term.toLowerCase())
              : false;
          });
        });
      }
    }

    // if we're given a sort and direction then do that first
    if (limitBySort) {
      myData = _.orderBy(myData, [limitBySort], [limitBySortDirection]);
    }

    // if we're not given a limitBy then just limit the whole list
    if (!limitBy) {
      myData = myData.slice(0, limit);
    } else if (limitBy) {
      // if we do have a limit by then go through the list and limit each group
      myData = Object.values(
        myData.reduce((acc, item) => {
          if (!acc[item[limitBy]]) {
            acc[item[limitBy]] = [];
          }
          if (acc[item[limitBy]].length < limit) {
            acc[item[limitBy]].push(item);
          }
          return acc;
        }, {})
      ).flat();
    }

    // if we're given a weight and operator then do that first
    if (weight && weightOperator) {
      weight = parseFloat(weight);

      myData = myData.filter((item) => {
        // console.log(`item.Weight: ${item.Weight} ${weightOperator} ${weight}`);

        switch (weightOperator) {
          case "gte":
            return item.Weight >= weight;
          case "lte":
            return item.Weight <= weight;
          case "gt":
            return item.Weight > weight;
          case "lt":
            return item.Weight < weight;
          case "eq":
            return item.Weight === weight;
          default:
            return true;
        }
      });
    }

    // handle quantity filtering if applicable
    if (
      settings.quantity &&
      settings.quantity !== "0" &&
      settings.quantityOperator
    ) {
      const quantity = parseInt(settings.quantity);
      const quantityOperator = settings.quantityOperator;

      myData = myData.filter((item) => {
        switch (quantityOperator) {
          case "gte":
            return item.Quantity >= quantity;
          case "lte":
            return item.Quantity <= quantity;
          case "gt":
            return item.Quantity > quantity;
          case "lt":
            return item.Quantity < quantity;
          case "eq":
            return item.Quantity === quantity;
          default:
            return true;
        }
      });
    }

    if (settings.multiplyScroll === true) {
      let myData10 = [];

      for (let i = 0; i < 10; i++) {
        myData10 = myData10.concat(_.cloneDeep(myData));
      }

      myData = myData10;
    }

    // for testing purposes trim this list to 50 items
    // myData = myData.slice(0, 50);

    // code only for testing; add row numbers to product name
    // myData = myData.map((item, index, array) => {
    //   item.ProductName = `${item.ProductName} (${index + 1}/${array.length})`;
    //   return item;
    // });

    return myData;
  },

};

export default inventoryHelper;
