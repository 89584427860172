var CompomentType = 
{
    CAROUSEL: "CAROUSEL",
    MATERIALTABLE: "MATERIALTABLE",
    VIRTUALIZEDTABLE : "VIRTUALIZEDTABLE",
};

var DataType = 
{
    Advertise: "Advertise",
    Flower: "Flower",
    Edible : "Edible",
    Price : "Price",
    Joints : "Joints",
    Extracts : "Extracts",
    Vape : "Vape",
};

export {CompomentType, DataType};