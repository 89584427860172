import React from "react";
import Loader from "react-loader-spinner";
import TreeView from "@material-ui/lab/TreeView"; // web.cjs is required for IE 11 support
import {
  StyledTreeItem,
  MinusSquare,
  PlusSquare,
  CloseSquare
} from "../../styled-components/StyledTreeViewItem";
import StoreOwnerTreeViewContext from "./StoreOwnerTreeViewContext";

// const getExpanded = store => {
//   if (!store) return [];

//   return [`store-owner-id-${store.storeOwnerId}`, `store-id-${store.id}`];
// };

// const getSelected = screen => {
//   if (!screen) return [];

//   return [`screen-id=${screen.id}`];
// };

const StoreOwnerTreeView = props => {
  return (
    <StoreOwnerTreeViewContext.Consumer>
      {({
        screensLoading,
        storeOwners,
        screens,
        selectedStore,
        selectedScreen,
        onScreenChange,
        onStoreChange,
        onStoreOwnerChange
      }) => (
        <TreeView
          defaultExpanded={["root", "1", "3", "4"]}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
          // expanded={getExpanded(selectedStore)}
          // selected={getSelected(selectedScreen)}
        >
          {screensLoading && (
            <Loader type="ThreeDots" color="#2BAD60" height={100} width={100} />
          )}
          {storeOwners &&
            storeOwners.map(storeOwner => (
              <StyledTreeItem
                key={"store-owner-id-" + storeOwner.id}
                nodeId={"store-owner-id-" + storeOwner.id}
                label={storeOwner.name}
                onClick={event => {
                  onStoreOwnerChange();
                }}
              >
                {storeOwner.stores &&
                  storeOwner.stores.map(store => (
                    <StyledTreeItem
                      key={"store-id-" + store.id}
                      nodeId={"store-id-" + store.id}
                      label={store.name}
                      onClick={event => {
                        onStoreChange(store);
                      }}
                    >
                      {screens &&
                        screens
                          .filter(screen => screen.storeId === store.id)
                          .map(screen => (
                            <StyledTreeItem
                              key={`screen-id-${screen.id}`}
                              nodeId={`screen-id-${screen.id}`}
                              label={screen.name}
                              onClick={event => {
                                onScreenChange({
                                  ...screen,
                                  config: JSON.parse(screen.config)
                                });
                              }}
                            />
                          ))}
                    </StyledTreeItem>
                  ))}
              </StyledTreeItem>
            ))}
        </TreeView>
      )}
    </StoreOwnerTreeViewContext.Consumer>
  );
};

export default StoreOwnerTreeView;
