import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Button = styled.div`
    border-radius: 3px;
    background: ${({ bgcolor }) => bgcolor};
    margin: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
`;
export const ButtonWrapper = styled.div`
    border: 1px solid #b1afaf;
    border-radius: 3px;
    width: 100%;
    height: 100%;
`;

export const Title = styled.p`
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    margin: 0 0 5px 0;
    font-size: 12px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
`;