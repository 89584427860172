import defaultTheme from "./defaultTheme";
import bananaRepublic from "./bananaRepublic";
// import blueDream from "./blueDream";
// import dinerPlacemat from "./dinerPlacemat";
// import longTallSally from "./longTallSally";
// import longTallShelly from "./longTallShelly";
import midnightChristmas from "./midnightChristmas";
// import midnightSimple from "./midnightSimple";
// import midnightVertigo from "./midnightVertigo";
// import organicMidnight from "./organicMidnight";
// import organicMorning from "./organicMorning";
import organicNoon from "./organicNoon";
import pastelFade from "./pastelFade";
import tanPlan from "./tanPlan";
import greenside from "./greenside";
import universalHerbs from "./universalHerbs";
import clinicCannabis from "./clinicCannabis";
import bigSmall from "./bigSmall";
import spaceExploration from "./spaceExploration";
import oasis from "./oasis";
import smyth from "./smyth";
import smythHuff from "./smythHuff";
import tablet from "./tabletTheme";
import deltaOne from "./deltaOne";
import deltaLeft from "./deltaLeft";
// import twoFace from "./twoFace";

export const map = {
  default: { label: "Default", theme: defaultTheme },
  bananaRepublic: { label: "Banana Republic", theme: bananaRepublic },
  // blueDream: blueDream,
  // dinerPlacemat: dinerPlacemat,
  // longTallSally: longTallSally,
  // longTallShelly: longTallShelly,
  midnightChristmas: { label: "Midnight Christmas", theme: midnightChristmas },
  // midnightSimple: midnightSimple,
  // midnightVertigo: midnightVertigo,
  // organicMidnight: organicMidnight,
  // organicMorning: organicMorning,
  organicNoon: { label: "Organic Noon", theme: organicNoon },
  pastelFade: { label: "Pastel Fade", theme: pastelFade },
  tanPlan: { label: "Tan Plan", theme: tanPlan },
  greenside: { label: "Greenside", theme: greenside },
  universalHerbs: { label: "Universal Herbs", theme: universalHerbs },
  clinicCannabis: { label: "Clinic Cannabis", theme: clinicCannabis },
  bigSmall: { label: "Big Small", theme: bigSmall },
  spaceExploration: { label: "Space Exploration", theme: spaceExploration },
  oasis: { label: "Oasis", theme: oasis },
  smyth: { label: "Smyth", theme: smyth },
  smytHuff: { label: "Smyth Huff Special", theme: smythHuff },
  tablet: { label: "Tablet", theme: tablet },
  deltaOne: { label: "Delta One", theme: deltaOne },
  deltaLeft: { label: "Delta Left", theme: deltaLeft },
  // twoFace: twoFace
};

export const themeOptions = () => {
  return Object.keys(map).map((key) => {
    return {
      id: key,
      label: map[key].label,
    };
  });
};

// Why not just pass the function in to the factory
const themeFactory = (id, label, palette, fontSizeCalculator) => {
  const base = map[id].theme(palette, fontSizeCalculator);

  return {
    ...base,
    id: id,
    label: label,
  };
};

export default themeFactory;
