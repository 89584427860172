import React, { useState } from "react";
import axios from "axios";
import * as appFunc from "../../App.Func";
import { makeStyles } from '@material-ui/core/styles';
import styles from './categoryStyle';
import Product from "./product";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const useStyles = makeStyles(styles);

const Category = (props) => {
  const { screenOption, storeId, size, division } = props;
  const [inventoryData, setInventoryData] = useState();

  const orientation = window.innerHeight > window.innerWidth;

  const loadInventory = () => {
    axios
        .get(`${appFunc.getApiUrl()}/Inventory/${storeId}/${screenOption.category}`)
        .then((result) => {
          if(screenOption.title === "Fruit Chews" || screenOption.title === "Chocolates") {
            setInventoryData(result.data.filter(product => {
              return product.Type === screenOption.title;
            }));
          } else {
            setInventoryData(result.data);
          }
        });
  }

  if (!inventoryData) {
    loadInventory();
    setInterval(loadInventory, 600000);
  }

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  let rows;

  switch (size) {
    case "half":
      window.innerHeight > window.innerWidth ? rows = 8 : rows = 10;
      break;
    case "third":
      window.innerHeight > window.innerWidth ? rows = 4 : rows = 8;
      // rows = 4;
      break;
    case "third half":
      window.innerHeight > window.innerWidth ? rows = 3 : rows = 3;
      break;
    default:
      rows = 8;
  }

  const dataDivision = Math.ceil(inventoryData ? inventoryData.length / rows : 8);
  const pages = [];
    for(let i = 1; i <= dataDivision; i++) {
      pages.push(i);
    }

  const dataPage = (data) => {
    return (
      <div>
        {data.map((product, index, products) => {
          return (
            <Product product={product} last={index === products.length - 1} division={division}/>
          )
        })}
      </div>
    )
  };
  
  const classes = useStyles();
  return (
    <div className={classes.category}>
      {screenOption.category != "Empty" && <div className={`${classes.title} ${orientation ? classes.titleSizeVert : classes.titleSizeHor}`}>{screenOption.title.toUpperCase()}</div>}
        {inventoryData && 
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={dataDivision > 1} //dataDivision > 1
            autoPlaySpeed={12000}
            arrows={false}
            itemClass='carousel-item-padding'
            sliderClass="carousel-height"
          >
            {pages.map(pageNum => {
              return (dataPage(inventoryData.slice(rows * (pageNum - 1), rows * pageNum), true))
            })}
          </Carousel>
        }
    </div>
  )
};

export default Category;