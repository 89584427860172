import * as appFunc from "../../App.Func";
import templateFactory from "../../tmpl/menus/menuTemplateFactory";
import axios from "axios";

import themeFactory from "../../themes/themeFactory";
import { map as paletteMap } from "../../palettes/paletteFactory";
import { map as layoutMap } from "../../layouts/layoutFactory";
const clone = require("rfdc")();
// This is the shape of the state required to dynamically render a screen.
// It should be thought of as a configuration object that gets fed to a view
// model generator.
// Areas are the most complex part of theschema. They have three potential subclasses.
// Placeholder
// {
//   id: "a", -- Id of the layout zone this area should render in
//   type: "placeholder" -- a gray box placeholder - no content configured
// }
// Menu
// {
//   id: "a", -- Id of the layout zone this area should render in
//   type: "menu", -- discriminator that indicates which subclass
//   category: "flower", -- drives the data query that populates the menu
//   backgroundImage: "some-url" -- background image to be loaded via url() css
// }
// Ad
// {
//   id: "a", -- Id of the layout zone this area should render in
//   type: "ad", -- discriminator that indicates which subclass
//   backgroundImage: "some-url" -- background image to be loaded via url() css
//   url: "some-url" -- Url to some add network content
// }
// Content
// {
//   id: "a", -- Id of the layout zone this area should render in
//   type: "content", -- discriminator that indicates which subclass
//   backgroundImage: "some-url" -- background image to be loaded via url() css
//   url: "some-url" -- Url to static content
// }

export const getPlaceholderArea = (id) => {
  return {
    id: id,
    type: "placeholder",
    settings: {},
  };
};

export const getAreaConfig = async (storeId, area) => {
  switch (area.type.toLowerCase()) {
    case "placeholder":
      break;
    case "ad":
      break;
    case "content":
      break;
    case "menu":
      return await getMenuConfig(storeId, area);
    default:
      break;
  }
};

// Builder function to build an area object and populate it's data.
const getMenuConfig = async (storeId, area) => {
  let config = {
    title: area.title,
    columns: [...templateFactory(area).columns], //TODO: get rid of this template stuff
    data: [],
    settings: {
      ...area.settings,
    },
  };

  //TODO: Ask what this block was for (maybe incomplete)
  // if (storeId === 0 || area.category.length < 3 || area.type) {
  //   return config;
  // }

  // const result = await axios.get(
  //   `${appFunc.getApiUrl()}/Inventory/${storeId}/${area.category.toLowerCase()}`
  // );

  //Modify data to combine units and values for display
  // console.log(
  //   result.data.map((dataPoint) => {
  //     return {
  //       ...dataPoint,
  //       Weight: dataPoint.Weight + dataPoint.Unit,
  //       Thc: dataPoint.Thc + dataPoint.CannaUnit,
  //       Price: "$" + dataPoint.Price,
  //       PriceGram: "$" + dataPoint.PriceGram,
  //       PriceTwoGram: "$" + dataPoint.PriceTwoGram,
  //       PriceHalfEighth: "$" + dataPoint.PriceHalfEighth,
  //       PriceQuarter: "$" + dataPoint.PriceQuarter,
  //       PriceHalfOunce: "$" + dataPoint.PriceHalfOunce,
  //       PriceOunce: "$" + dataPoint.PriceOunce,
  //     };
  //   })
  // );

  // if (result.status === 200) {
  //   config.data = [...result.data];
  // }

  return config;
};

export const buildScreenModel = async (screen) => {
  const fontSizeCalculator = (fontSize) => {
    return `${
      (fontSize * window.innerWidth) /
      (window.innerWidth === 1080 ? 1080 : 1920)
    }px`;
  };

  let viewModel = {
    ...clone(screen.config),
    screenId: screen.id,
    storeId: screen.storeId,
    theme: themeFactory(
      screen.config.themeId,
      screen.config.themeId,
      paletteMap[screen.config.paletteId],
      fontSizeCalculator
    ),
    layout: layoutMap.find((lm) => lm.id === screen.config.layoutId),
    logo:
      typeof screen.config.logo === "string"
        ? screen.config.logo
        : `${appFunc.getApiUrl()}/Blob/${screen.config.logo.folder}/${
            screen.config.logo.fileName
          }`,
    background: screen.config.background
      ? `${appFunc.getApiUrl()}/Blob/${screen.config.background.folder}/${
          screen.config.background.fileName
        }`
      : "",
  };

  for (var index = 0; index < viewModel.areas.length; index++) {
    const areaConfig = await getAreaConfig(
      screen.storeId,
      viewModel.areas[index]
    );
    viewModel.areas[index] = {
      ...clone(viewModel.areas[index]),
      ...areaConfig,
    };
  }
  return viewModel;
};

export default getAreaConfig;
