const palette = {
  id: "deltaTwo",
  label: "Delta Two",
  backgroundColor: "white",
  header: "rgba(0, 0, 0, 0)",
  table: "rgba(255, 255, 255, 0.95)",
  tableHeader: "white",
  grid: "rgb(240, 240, 240)", // Table Grid Color
  tableHighlight: "rgba(62, 62, 62, 0)",
  textHeader: "black",
  textTable: "black",
  textTableHeader: "black",
};

export default palette;
