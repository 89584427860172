import React from "react";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";

const filterOptions = [
  "Less Than",
  "Equal To",
  "Greater Than",
  "Between",
];

const pricePoints = [
  "Price",
  "PriceGram",
  "PriceEighth",
  "PriceQuarter",
  "PriceHalfOunce",
  "PriceOunce",
];

const PriceFilter = (props) => {
  const { filterOption, filterNumbers, pricePoint, index, handleFilterUpdate } = props;


  const handleFilterOptionSet = (event) => {
    const updatedFilter = {
      filterOption: event.target.value,
      filterNumbers: [],
      pricePoint: [],
    };
    handleFilterUpdate(updatedFilter, index);
  };

  const handleFilterNumbersSet = (event, between) => {
    const updatedFilter = {
      filterOption,
      filterNumbers: between !== undefined ? (
        between === 0 ? [parseFloat(event.target.value), filterNumbers[1]] : [filterNumbers[0], parseFloat(event.target.value)]
      ) : [parseFloat(event.target.value)],
      pricePoint,
    };
    handleFilterUpdate(updatedFilter, index);
  };

  // const handlePricePointSet = (event) => {
  //   const updatedFilter = {
  //     filterOption,
  //     filterNumbers,
  //     pricePoint: event.target.value,
  //   };
  //   handleFilterUpdate(updatedFilter, index);
  // };

  return (
    <Grid container>
      <Grid item xs={4}>
        <FormControl>
          <InputLabel>Filter {index}</InputLabel>
          <Select
            style={{ width: "150px" }}
            value={filterOption}
            onChange={handleFilterOptionSet}
          >
            {filterOptions.map((filterOptionTxt) => (
              <MenuItem value={filterOptionTxt}>{filterOptionTxt}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {filterOption ? (
        filterOption === "Between" ? (
          <Grid item xs={8}>
            <FormControl>
              <InputLabel>-</InputLabel>
              <Input
                style={{ width: "50px" }}
                type="number"
                endAdornment="$"
                value={filterNumbers[0]}
                onChange={(event) => {
                  handleFilterNumbersSet(event, 0)
                }}
              />
            </FormControl>
            <FormControl>
              <InputLabel>-</InputLabel>
              <Input
                style={{ width: "50px" }}
                type="number"
                endAdornment="$"
                value={filterNumbers[1]}
                onChange={(event) => {
                  handleFilterNumbersSet(event, 1)
                }}
              />
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={8}>
            <FormControl>
              <InputLabel>-</InputLabel>
              <Input
                style={{ width: "50px" }}
                type="number"
                endAdornment="$"
                value={filterNumbers[0]}
                onChange={(event) => {
                  handleFilterNumbersSet(event)
                }}
              />
            </FormControl>
          </Grid>
        )
      ) : (
        <div />
      )}
      {/* {filterOption ? (
        <Grid item xs={3}>
          <FormControl>
            <InputLabel>Increment Restriction</InputLabel>
            <Select
              style={{ width: "200px" }}
              multiple
              value={pricePoint}
              onChange={handlePricePointSet}
            >
              {pricePoints.map((pricePoint) => (
                <MenuItem value={pricePoint}>{pricePoint}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      ) : (
        <div />
      )} */}
    </Grid>
  );
};

export default PriceFilter;