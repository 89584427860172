import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import lodash from "lodash";
import { func, string } from "prop-types";

const propTypes = {
  id: string,
  label: string,
  onChange: func,
  value: string,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
}));

const CustomizedSelect = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.value);
  if (!value) console.log(`CustomizedSelect initial value undefined`);
  const [open, setOpen] = React.useState(false);
  const handleChange = (event) => {
    setValue(event.target.value);
    if (lodash.isNil(props.onChange)) {
      //TODO
    } else {
      props.onChange(event.target.value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id={"open-select-label-" + props.id}>
          {props.label}
        </InputLabel>
        <Select
          labelId={"open-select-label-" + props.id}
          id={`customized-select-` + props.id}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={props.value}
          onChange={handleChange}
        >
          {/* <MenuItem key={`select-item-none-${props.id}`} value="">
            <em>None</em>
          </MenuItem> */}
          {props.items.map((value, index) => {
            return (
              <MenuItem
                key={"select-item-" + props.id + "-index-" + index}
                value={value.id}
              >
                {value.image ? (
                  <img src={value.image} style={{ maxHeight: "50px" }}></img>
                ) : (
                  value.label
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

// TODO: This is bloatware that I can't kill yet, but I will.....oh I will DCS

CustomizedSelect.propTypes = propTypes;

export default CustomizedSelect;
