import React from "react";
import "./App.css";
import Admin from "./pages/admin";
import Editor from "./pages/editor";
import ScreenView from "./components/Screen/ScreenView";
import TabletScreen from "./components/Screen/TabletScreen";
import TextScreen from "./pages/textScreen/textScreen.js";
import ImageScreen from "./pages/imageScreen/imageScreen.js";
import { BrowserRouter as Router, Route } from "react-router-dom";

function AppRoute() {
  return (
    <>
      <Router>
        <div className="App">
          <Route exact path="/" component={Editor} />
          <Route path="/admin" component={Admin} />
          <Route
            path="/editor/:screenId"
            exact
            render={(props) => <Editor {...props.match.params} />}
          />
          <Route
            path="/screen/:screenId"
            exact
            render={(props) => <ScreenView {...props.match.params} />}
          />
          <Route
            path="/screen/v1/:screenId"
            exact
            render={(props) => <ScreenView {...props.match.params} />}
          />
          <Route
            path="/screen/v2/:screenId"
            exact
            render={(props) => <ScreenView {...props.match.params} />}
          />
          <Route
            path="/sante"
            render={(props) => <TabletScreen {...props.match.paraps} />}
          />
          <Route
            path="/mobile/:referenceId"
            render={(props) => <ScreenView {...props.match.params} />}
          />
          <Route
            path="/vero/:storeId/:screenNumber"
            render={(props) => <TextScreen {...props.match.params} />}
          />
          <Route 
            path="/vero-image/:storeId/:category"
            render={(props) => <ImageScreen {...props.match.params} />}
          />
        </div>
      </Router>
    </>
  );
}

export default AppRoute;
