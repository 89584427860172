const productStyle = {
  container: {
    // background: "grey",
    display: "grid",
    gridTemplateColumns: "16.66% 16.66% 16.66% 16.66% 16.66% 16.66%",
    gridTemplateRows: "auto",
    gridTemplateAreas: `
    'name name name name name price'
    'info info info info info info'
    `,
    alignContent: "center",
    fontFamily: "Roboto Regular",
  },
  containerHeightVert: {
    height: "90px",
    fontSize: "28px",
  },
  containerHeightHorHalf: {
    height: "91px",
    fontSize: "28px",
  },
  containerHeightHorThird: {
    height: "112.5px",
    fontSize: "28px",
  },
  containerMargin: {
    marginBottom: "15px",
  },
  containerNoMargin: {
    marginBottom: "0px",
  },
  name: {
    gridArea: "name",
    textAlign: "left",
    fontFamily: "Roboto Black",
  },
  nameThird: {
    gridArea: "name",
    textAlign: "left",
    fontFamily: "Roboto Black",
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
  },
  price: {
    gridArea: "price",
    fontFamily: "Roboto Black",
  },
  info: {
    gridArea: "info",
    justifySelf: "left",
  },
  infoSizeVert: {
    fontSize: "25px",
  },
  infoSizeHorHalf: {
    fontSize: "23px",
  },
  infoSizeHorThird: {
    fontSize: "23px",
  },
  bold: {
    fontFamily: "Roboto Bold",
  },
  line: {
    margin: "0px 10px",
  },
};

export default productStyle;