const defaultPalette = {
  id: "default",
  label: "Default",
  backgroundColor: "white",
  header: "black",
  table: "white",
  tableHeader: "white",
  grid: "#BA834D", // Table Grid Color
  tableHighlight: "#ECECEC",
  textHeader: "white",
  textTable: "black",
  textTableHeader: "black",
};

export default defaultPalette;
