import layout0 from "../assets/img/icons/layouts/Layout0.png";
import layout1 from "../assets/img/icons/layouts/Layout1.png";
import layout2 from "../assets/img/icons/layouts/Layout2.png";
import layout3 from "../assets/img/icons/layouts/Layout3.png";
import layout4 from "../assets/img/icons/layouts/Layout4.png";
import layout5 from "../assets/img/icons/layouts/Layout5.png";
import layout6 from "../assets/img/icons/layouts/Layout6.png";
import layout7 from "../assets/img/icons/layouts/Layout7.png";
import layout8 from "../assets/img/icons/layouts/Layout8.png";
import layout9 from "../assets/img/icons/layouts/Layout9.png";
import layout10 from "../assets/img/icons/layouts/Layout10.png";
import layout11 from "../assets/img/icons/layouts/Layout11.png";
import layout12 from "../assets/img/icons/layouts/Layout12.png";
import layout13 from "../assets/img/icons/layouts/Layout13.png";
import layout14 from "../assets/img/icons/layouts/Layout14.png";
import layout15 from "../assets/img/icons/layouts/Layout15.png";
import layout16 from "../assets/img/icons/layouts/Layout16.png";
import layout17 from "../assets/img/icons/layouts/Layout17.png";
import layout18 from "../assets/img/icons/layouts/Layout18.png";
import layout19 from "../assets/img/icons/layouts/Layout19.png";
import layout20 from "../assets/img/icons/layouts/Layout20.png";
import layout23 from "../assets/img/icons/layouts/Layout23.png";
import layout24 from "../assets/img/icons/layouts/Layout24.png";
import layout25 from "../assets/img/icons/layouts/Layout25.png";
import layout26 from "../assets/img/icons/layouts/Layout26.png";
import layout27 from "../assets/img/icons/layouts/Layout27.png";
import layout28 from "../assets/img/icons/layouts/Layout28.png";
import layout29 from "../assets/img/icons/layouts/Layout29.png";

export const map = [
  {
    id: "0",
    label: "3 columns",
    value: [
      { i: "a", x: 0, y: 0, w: 1, h: 6, static: true },
      { i: "b", x: 1, y: 0, w: 1, h: 6, static: true },
      { i: "c", x: 2, y: 0, w: 1, h: 6, static: true },
    ],
    image: layout0,
  },
  {
    id: "1",
    label: "3 columns, mid split",
    value: [
      { i: "a", x: 0, y: 0, w: 1, h: 6, static: true },
      { i: "b", x: 1, y: 0, w: 1, h: 3, static: true },
      { i: "c", x: 2, y: 0, w: 1, h: 6, static: true },
      { i: "d", x: 1, y: 3, w: 1, h: 3, static: true },
    ],
    image: layout1,
  },
  {
    id: "2",
    label: "3 columns, left split",
    value: [
      { i: "c", x: 0, y: 0, w: 1, h: 3, static: true },
      { i: "b", x: 1, y: 0, w: 1, h: 6, static: true },
      { i: "a", x: 2, y: 0, w: 1, h: 6, static: true },
      { i: "d", x: 0, y: 3, w: 1, h: 3, static: true },
    ],
    image: layout2,
  },
  {
    id: "3",
    label: "3 columns, right split",
    value: [
      { i: "a", x: 0, y: 0, w: 1, h: 6, static: true },
      { i: "b", x: 1, y: 0, w: 1, h: 6, static: true },
      { i: "c", x: 2, y: 0, w: 1, h: 3, static: true },
      { i: "d", x: 2, y: 3, w: 1, h: 3, static: true },
    ],
    image: layout3,
  },
  {
    id: "4",
    label: "2 columns, left center",
    value: [
      { i: "a", x: 0, y: 0, w: 2, h: 6, static: true },
      { i: "b", x: 2, y: 0, w: 1, h: 6, static: true },
    ],
    image: layout4,
  },
  {
    id: "5",
    label: "2 columns, right center",
    value: [
      { i: "a", x: 0, y: 0, w: 1, h: 6, static: true },
      { i: "b", x: 1, y: 0, w: 2, h: 6, static: true },
    ],
    image: layout5,
  },
  {
    id: "6",
    label: "2 columns, left center, right split",
    value: [
      { i: "a", x: 0, y: 0, w: 2, h: 6, static: true },
      { i: "b", x: 2, y: 0, w: 1, h: 3, static: true },
      { i: "c", x: 2, y: 3, w: 1, h: 3, static: true },
    ],
    image: layout6,
  },
  {
    id: "7",
    label: "2 columns, right center, left split",
    value: [
      { i: "a", x: 0, y: 0, w: 1, h: 3, static: true },
      { i: "b", x: 0, y: 3, w: 1, h: 3, static: true },
      { i: "c", x: 1, y: 0, w: 2, h: 6, static: true },
    ],
    image: layout7,
  },
  {
    id: "8",
    label: "2 columns, left center, left split",
    value: [
      { i: "a", x: 0, y: 0, w: 2, h: 3, static: true },
      { i: "b", x: 0, y: 3, w: 2, h: 3, static: true },
      { i: "c", x: 2, y: 0, w: 1, h: 6, static: true },
    ],
    image: layout8,
  },
  {
    id: "9",
    label: "2 columns, right center, right split",
    value: [
      { i: "a", x: 0, y: 0, w: 1, h: 6, static: true },
      { i: "b", x: 1, y: 3, w: 2, h: 3, static: true },
      { i: "c", x: 1, y: 0, w: 2, h: 3, static: true },
    ],
    image: layout9,
  },
  {
    id: "10",
    label: "2 columns, right center, top right split",
    value: [
      { i: "a", x: 0, y: 0, w: 1, h: 6, static: true },
      { i: "b", x: 1, y: 0, w: 1, h: 3, static: true },
      { i: "c", x: 2, y: 0, w: 1, h: 3, static: true },
      { i: "d", x: 1, y: 3, w: 2, h: 3, static: true },
    ],
    image: layout10,
  },
  {
    id: "11",
    label: "2 columns, right center, bottom right split",
    value: [
      { i: "a", x: 0, y: 0, w: 1, h: 6, static: true },
      { i: "b", x: 1, y: 3, w: 1, h: 3, static: true },
      { i: "c", x: 2, y: 3, w: 1, h: 3, static: true },
      { i: "d", x: 1, y: 0, w: 2, h: 3, static: true },
    ],
    image: layout11,
  },
  {
    id: "12",
    label: "2 columns, left center, top left split",
    value: [
      { i: "a", x: 0, y: 0, w: 1, h: 3, static: true },
      { i: "b", x: 1, y: 0, w: 1, h: 3, static: true },
      { i: "c", x: 0, y: 3, w: 2, h: 3, static: true },
      { i: "d", x: 2, y: 0, w: 1, h: 6, static: true },
    ],
    image: layout12,
  },
  {
    id: "13",
    label: "2 columns, left center, bottom left split",
    value: [
      { i: "a", x: 0, y: 3, w: 1, h: 3, static: true },
      { i: "b", x: 1, y: 3, w: 1, h: 3, static: true },
      { i: "c", x: 0, y: 0, w: 2, h: 3, static: true },
      { i: "d", x: 2, y: 0, w: 1, h: 6, static: true },
    ],
    image: layout13,
  },
  {
    id: "14",
    label: "1 column",
    value: [{ i: "a", x: 0, y: 0, w: 3, h: 6, static: true }],
    image: layout14,
  },
  {
    id: "15",
    label: "2 columns, even split",
    value: [
      { i: "a", x: 0, y: 0, w: 1.5, h: 6, static: true },
      { i: "b", x: 1.5, y: 0, w: 1.5, h: 6, static: true },
    ],
    image: layout15,
  },
  {
    id: "16",
    label: "vert 2 row",
    value: [
      { i: "a", x: 0, y: 0, w: 3, h: 3, static: true },
      { i: "b", x: 0, y: 3, w: 3, h: 3, static: true },
    ],
    image: layout16,
  },
  {
    id: "17",
    label: "vert 2 row top split",
    value: [
      { i: "a", x: 0, y: 0, w: 1.5, h: 3, static: true },
      { i: "b", x: 1.5, y: 0, w: 1.5, h: 3, static: true },
      { i: "c", x: 0, y: 3, w: 3, h: 3, static: true },
    ],
    image: layout17,
  },
  {
    id: "18",
    label: "vert 2 row bottom split",
    value: [
      { i: "a", x: 0, y: 0, w: 3, h: 3, static: true },
      { i: "b", x: 1.5, y: 3, w: 1.5, h: 3, static: true },
      { i: "c", x: 0, y: 3, w: 1.5, h: 3, static: true },
    ],
    image: layout18,
  },
  {
    id: "19",
    label: "vert 2 row both split",
    value: [
      { i: "a", x: 0, y: 0, w: 1.5, h: 3, static: true },
      { i: "b", x: 1.5, y: 0, w: 1.5, h: 3, static: true },
      { i: "c", x: 0, y: 3, w: 1.5, h: 3, static: true },
      { i: "d", x: 1.5, y: 3, w: 1.5, h: 3, static: true },
    ],
    image: layout19,
  },
  {
    id: "20",
    label: "vert 3 row",
    value: [
      { i: "a", x: 0, y: 0, w: 3, h: 2, static: true },
      { i: "b", x: 0, y: 2, w: 3, h: 2, static: true },
      { i: "c", x: 0, y: 4, w: 3, h: 2, static: true },
    ],
    image: layout20,
  },
  {
    id: "21",
    label: "wide 3 area",
    value: [
      { i: "a", x: 0, y: 0, w: 1.4, h: 6, static: true },
      { i: "b", x: 1.4, y: 0, w: 0.8, h: 6, static: true },
      { i: "c", x: 2.2, y: 0, w: 0.8, h: 6, static: true },
    ],
  },
  {
    id: "22",
    label: "wide 4 area",
    value: [
      { i: "a", x: 0, y: 0, w: 1.4, h: 6, static: true },
      { i: "b", x: 1.4, y: 0, w: 0.8, h: 6, static: true },
      { i: "c", x: 2.2, y: 0, w: 0.8, h: 3, static: true },
      { i: "d", x: 2.2, y: 3, w: 0.8, h: 3, static: true },
    ],
  },
  {
    id: "23",
    label: "2 wide 2 short",
    value: [
      { i: "a", x: 0, y: 0, w: 2, h: 3, static: true },
      { i: "b", x: 0, y: 3, w: 2, h: 3, static: true },
      { i: "c", x: 2, y: 0, w: 1, h: 3, static: true },
      { i: "d", x: 2, y: 3, w: 1, h: 3, static: true },
    ],
    image: layout23,
  },
  {
    id: "24",
    label: "2 wide 2 short flip",
    value: [
      { i: "a", x: 0, y: 0, w: 1, h: 3, static: true },
      { i: "b", x: 0, y: 3, w: 1, h: 3, static: true },
      { i: "c", x: 1, y: 0, w: 2, h: 3, static: true },
      { i: "d", x: 1, y: 3, w: 2, h: 3, static: true },
    ],
    image: layout24,
  },
  {
    id: "25",
    label: "6 short",
    value: [
      { i: "a", x: 0, y: 0, w: 1, h: 3, static: true },
      { i: "b", x: 0, y: 3, w: 1, h: 3, static: true },
      { i: "c", x: 1, y: 0, w: 1, h: 3, static: true },
      { i: "d", x: 1, y: 3, w: 1, h: 3, static: true },
      { i: "e", x: 2, y: 0, w: 1, h: 3, static: true },
      { i: "f", x: 2, y: 3, w: 1, h: 3, static: true },
    ],
    image: layout25,
  },
  {
    id: "26",
    label: "half and half",
    value: [
      { i: "a", x: 0, y: 0, w: 1.5, h: 6, static: true },
      { i: "b", x: 1.5, y: 3, w: 1.5, h: 3, static: true },
      { i: "c", x: 1.5, y: 0, w: 1.5, h: 3, static: true },
      
    ],
    image: layout26,
  },
  {
    id: "27",
    label: "half and half flipped",
    value: [
      { i: "a", x: 0, y: 0, w: 1.5, h: 3, static: true },
      { i: "b", x: 0, y: 3, w: 1.5, h: 3, static: true },
      { i: "c", x: 1.5, y: 0, w: 1.5, h: 6, static: true },
    ],
    image: layout27,
  },
  {
    id: "28",
    label: "thirds and halves",
    value: [
      { i: "a", x: 0, y: 0, w: 1, h: 4, static: true },
      { i: "b", x: 0, y: 4, w: 1, h: 2, static: true },
      { i: "c", x: 1, y: 0, w: 1, h: 4, static: true },
      { i: "d", x: 1, y: 4, w: 1, h: 2, static: true },
      { i: "e", x: 2, y: 0, w: 1, h: 3, static: true },
      { i: "f", x: 2, y: 3, w: 1, h: 3, static: true },
    ],
    image: layout28,
  },
  {
    id: "29",
    label: "vert top split",
    value: [
      { i: "a", x: 0, y: 0, w: 1.5, h: 2, static: true },
      { i: "b", x: 1.5, y: 0, w: 1.5, h: 2, static: true },
      { i: "c", x: 0, y: 2, w: 3, h: 2, static: true },
      { i: "d", x: 0, y: 4, w: 3, h: 2, static: true },
    ],
    image: layout29,
  }
];

export const layoutOptions = () => {
  return Object.keys(map).map((key) => {
    return {
      id: map[key].id,
      label: map[key].label,
    };
  });
};
