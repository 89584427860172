const palette = {
  id: "clinicCannabis",
  label: "Clinic Cannabis",
  backgroundColor: "white",
  header: "rgba(255, 255, 255, 0)",
  table: "rgba(255, 255, 255, 0.7)",
  tableHeader: "white",
  grid: "black", // Table Grid Color
  tableHighlight: "rgba(240, 240, 240, 0.7)",
  textHeader: "black",
  textTable: "black",
  textTableHeader: "black",
};

export default palette;
