import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import styles from "./textScreenStyle";
import "./textScreen.css";

import Category from "./category";

const useStyles = makeStyles(styles);

const TextScreen = (props) => {
  const { storeId, screenNumber } = props;
  const categoryOptions = {
    flower: {category: "Flower", title: "Flower",},
    joint: {category: "Joint", title: "Prerolls"},
    vape: {category: "Vape", title: "Vapes"},
    extract: {category: "Extract", title: "Concentrates"},
    topical: {category: "Topical", title: "Topicals"},
    tincture: {category: "Tincture", title: "Tinctures"},
    fruitChews: {category: "Edible", title: "Fruit Chews"},
    chocolate: {category: "Edible", title: "Chocolates"},
    empty: {category: "Empty", title: "Empty"},
  }
  const screenOptions = {
    1: [categoryOptions.flower, categoryOptions.joint],
    2: [categoryOptions.vape, categoryOptions.extract],
    3: [categoryOptions.topical, categoryOptions.tincture, categoryOptions.empty],
    4: [categoryOptions.fruitChews, categoryOptions.chocolate],
    5: [categoryOptions.vape, categoryOptions.empty, categoryOptions.extract],
    6: [categoryOptions.chocolate, categoryOptions.fruitChews, categoryOptions.topical, categoryOptions.tincture],
  }

  const orientation = window.innerHeight > window.innerWidth;

  const classes = useStyles();
  return (
    <div className={orientation ? classes.pageContainerVert : classes.pageContainerHor}>
      {screenOptions[screenNumber].map((screenOption, index, array) => {
        if(array.length === 2) {
          return (
            <div className={orientation ? classes.categoryContainerHalfVert : classes.categoryContainerHalfHor}>
              <Category screenOption={screenOption} storeId={storeId} size={"half"} division={"half"}/>
            </div>
          );
        } else if(array.length === 3){
          return (
            <div className={orientation ? classes.categoryContainerThirdVert : classes.categoryContainerThirdHor}>
              <Category screenOption={screenOption} storeId={storeId} size={"third"} division={"third"}/>
            </div>
          );
        } else if (array.length === 4){
          if(index < 2) {
            return (
              <div className={orientation ? classes.categoryContainerThirdVert : classes.categoryContainerThirdHor}>
                <Category screenOption={screenOption} storeId={storeId} size={"third"} division={"third"}/>
              </div>
            );
          } else {
            return (
              <div className={orientation ? classes.categoryContainerThirdVert : classes.categoryContainerThirdHorHalf}>
                <Category screenOption={screenOption} storeId={storeId} size={"third half"} division={"third"}/>
              </div>
            )
          }
        }
      })}
    </div>
  )
};

export default TextScreen;