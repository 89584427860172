import themeBase from "./themeBase";
import palette from "../palettes/defaultPalettes/organicNoon";

const factory = (p = palette, fontSizeCalculator) => {
  const base = themeBase(p);

  return {
    id: "organicNoon",
    label: "Organic Noon",
    main: {
      ...base.main,
    },
    container: {
      ...base.container,
      borderWidth: "1px 0px 1px 1px",
      borderStyle: "solid",
      borderColor: p.grid,
    },
    menu: {
      header: {
        wrapper: {
          ...base.menu.header.wrapper,
        },
        style: {
          ...base.menu.header.style,
          fontFamily: "Montserrat Light",
          // fontWeight: 300,
          fontSize: fontSizeCalculator(80),
          letterSpacing: "4.8px",
          textTransform: "capitalize",
          padding: "50px 30px 10px",
          textAlign: "left",
        },
      },
      table: {
        style: {
          ...base.menu.table.style,
          marginTop: "10px",
          fontFamily: "Open Sans",
          borderCollapse: "unset",
        },
        headerRow: {
          display: "none",
        },
        header: {
          style: {
            ...base.menu.table.header.style,
            fontSize: fontSizeCalculator(38),
          },
          overrides: {
            ...base.menu.table.header.overrides,
          },
        },
        data: {
          style: {
            ...base.menu.table.data.style,
            fontSize: fontSizeCalculator(25),
            padding: "5px",
            borderWidth: "1px 0px 1px 1px",
            borderStyle: "solid",
            borderColor: p.grid,
          },
          overrides: {
            "0": {
              ...base.menu.table.data.overrides["0"],
              fontSize: fontSizeCalculator(30),
            },
          },
        },
      },
    },
    specialSettings: {
      ...base.specialSettings,
    },
    advertisement: {
      ...base.advertisement,
    },
    content: {
      ...base.content,
    },
  };
};

export default factory;
