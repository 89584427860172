import axios from "axios";
import ActionType from "../../App.ActionType";
import * as appFunc from "../../App.Func";

const selectScreen = screenId => {
  return async dispatch => {
    dispatch({
      type: ActionType.SET_SELECTED_SCREEN,
      payload: { screenId: screenId }
    });
  };
};

const createSaveCurrentContentsDataAction = (contents, layout) => {
  return async dispatch => {
    let postContentsDataApiUrl = `${appFunc.getApiUrl()}/screen`;
    const postContentsDataResult = await axios.post(
      postContentsDataApiUrl,
      contents
    );
    if (postContentsDataResult.status === 200) {
      dispatch({
        type: ActionType.SCREEN_SAVED,
        payload: { success: true, data: contents, layout: layout }
      });
    }
  };
};

const createGragContentsDataAction = () => {
  return async dispatch => {
    dispatch({
      type: ActionType.DRAGGABLE_BUTTON_CLICKED,
      payload: { draggable: true }
    });
  };
};

//

const createFetchingAllTreeViewDataAction = () => {
  return async dispatch => {
    dispatch({
      type: ActionType.STORE_OWNERS_LOADING,
      payload: {
        storeOwnersLoading: true
      }
    });
  };
};

const createFetchingTreeViewDataAction = () => {
  return async dispatch => {
    try {
      let getStoresApiUrl = `${appFunc.getApiUrl()}/shop/Getshops`;
      let getScreensByStoreIdApiUrl = `${appFunc.getApiUrl()}/screen/GetScreensFlat`;

      let stores = [];
      let screens = [];
      const getStoresResult = await axios.get(getStoresApiUrl);

      if (getStoresResult.status === 200) {
        stores = getStoresResult;
        //For testing, just on screen 1
        for (
          let storeIdx = 0;
          storeIdx < stores.length && storeIdx < 3;
          storeIdx++
        ) {
          const screensByStoreIdResult = await axios.post(
            getScreensByStoreIdApiUrl,
            { filter: { shopId: stores[storeIdx].id } }
          );

          if (screensByStoreIdResult.status === 200) {
            screens = [...screensByStoreIdResult.data.model];
          }
        }

        dispatch({
          type: ActionType.STORE_OWNERS_LOADING,
          payload: { loadingTreeView: false }
        });

        dispatch({
          type: ActionType.STORE_OWNERS_FETCHED,
          payload: { stores: stores, screens: screens }
        });
      }

      //const getThemeCustomizedResult = await axios.get('/theme', {params: {screenId: "_1"}} );
      //let results = customizedThemes.filter(theme => theme.screenId === "_1");
      let getThemeCustomizedResult = {
        status: 200,
        data: { themes: [], data: [] }
      };
      if (getThemeCustomizedResult.status === 200) {
        dispatch({
          type: ActionType.PICTURE_CONTENT_DATA_FETCHED,
          payload: getThemeCustomizedResult.data
        });
      }
    } catch (error) {
      console.log(`error: ${JSON.stringify(error, null, " ")}`);
    }
  };
};

export {
  selectScreen,
  createFetchingAllTreeViewDataAction,
  createFetchingTreeViewDataAction,
  createSaveCurrentContentsDataAction,
  createGragContentsDataAction
};
