import React, { useState, useEffect } from "react";
import Screen from "./Screen";
import ScreenContext from "./ScreenContext";
import * as appFunc from "../../App.Func";
import { buildScreenModel } from "./ScreenActions";
import axios from "axios";

const ScreenView = (props) => {
    const { screenId, referenceId } = props;
    const [selectedScreen, setSelectedScreen] = useState(null);
    const [taxData, setTaxData] = useState({});
    //console.log(taxData)
    const fetchScreen = async (url, isNeedToSave) => {
        const response = await axios.get(`${appFunc.getApiUrl()}${url}`);
        const newScreen = {
            ...response.data,
            config: JSON.parse(response.data.config),
        };
        newScreen.model = await buildScreenModel(newScreen);
        if (isNeedToSave) {
            setSelectedScreen(newScreen);
        }
        localStorage.setItem(url, JSON.stringify({ data: response.data, timeAquired: new Date() }));
    };

    

    const fetchTax = (url, isNeedToSave) => {
        axios
            .get(`${appFunc.getApiUrl()}${url}`)
            .then((response) => {
                if (isNeedToSave) {
                    setTaxData(response.data);
                }
                localStorage.setItem(
                    url,
                    JSON.stringify({ data: response.data, timeAquired: new Date() })
                );
            })
            .catch((err) => {});
    };

    useEffect(() => {
      const retrieveScreen = async (url) => {
        const storage = JSON.parse(localStorage.getItem(url));
        if (storage && storage.data) {
          const screenData = JSON.parse(localStorage.getItem(url)).data;
          const newScreen = {
            ...screenData,
            config: JSON.parse(screenData.config),
          };
          newScreen.model = await buildScreenModel(newScreen);
          setSelectedScreen(newScreen);
          fetchScreen(url, true);
        } else {
          fetchScreen(url, true);
        }
      };
      const retrieveTax = (url) => {
        const storage = JSON.parse(localStorage.getItem(url));
        if (storage && storage.data) {
          setTaxData(storage.data);
          fetchTax(url, true);
        } else {
          fetchTax(url, true);
        }
      };

      let screenUrl;
      if (screenId) {
          screenUrl = `/Screen/${screenId}`;
      } else {
          screenUrl = `/Screen/ByRef/${referenceId}`;
      }
      retrieveScreen(screenUrl);

      let taxUrl;
      if (screenId) {
          taxUrl = `/Store/ByScreenId/${screenId}`;
      } else {
          taxUrl = `/Store/ByScreenRef/${referenceId}`; 
      }
      retrieveTax(taxUrl);
    }, [referenceId, screenId]);

    return (
        <div style={{ width: "100%" }}>
            {selectedScreen && taxData && (
                <ScreenContext.Provider
                    value={{
                        ...selectedScreen.model,
                        taxData,
                        editMode: false,
                        socialMediaHandle: selectedScreen.config.socialMediaHandle,
                        orientation: selectedScreen.orientation,
                        config: selectedScreen.config,
                    }}
                >
                    <Screen />
                </ScreenContext.Provider>
            )}
        </div>
    );
};

export default ScreenView;
