const pastelFade = {
  id: "pastelFade",
  label: "Pastel Fade",
  backgroundColor: "#F9F6E6",
  header: "#FCB8A5", //#B5D8D2, #FEE68C
  table: "#FACCBF", //#CFDFDC
  tableHeader: "#FACCBF", //#CFDFDC
  grid: "#BA834D", // Table Grid Color
  tableHighlight: "#F8BBAA", //#C2D6D2
  textHeader: "#323232",
  textTable: "black",
  textTableHeader: "black",
};

export default pastelFade;
